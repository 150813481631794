import React from "react";

export const NotificationContext = React.createContext(
    {
        currentOrganizationId:"",
        setcurrentOrganizationId: (id: string) => {}
    });

function subscribe(eventName:string, listener:any) {
    //console.log("New subscription: ", eventName, ": ",listener)
    document.addEventListener(eventName, listener);
}

function unsubscribe(eventName: string, listener: any) {
    //console.log("Unsubscribed: ", eventName, ": ",listener)
    document.removeEventListener(eventName, listener);
}

function publish(eventName:string, data: any) {
    //console.log("Event happened: ", eventName, ": ",data)
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe};
