import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    InputNumber,
    MenuProps, message, Modal,
    Row,
    Select,
    Space,
    Spin,
    Switch
} from "antd";
import React, {useEffect, useState} from 'react';
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import Product from "../../../types/Product";
import {DownOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import InvoiceItem from "../../../types/invoiceItem";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";
import currencies from "../../../types/currencies.json"

interface createParams {
    createId? : string;
    aftercreate? : (data: any) => void;
}

interface Params {
    id: string;
}
export default function ProductDetailPage({createId, aftercreate}: createParams) {
    const {t} = useTranslation();
    // @ts-ignore
    const {id} =  useParams<Params>()

    const the_id = createId? createId : id;


    const history = useHistory();

    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const userOrganisation = AuthService.getCurrentOrganisation();

    const [form] = Form.useForm();


    const [isLoading, setisLoading] = useState(false);
    const[isProductVal, setisProductVal]=useState( false);





/*
    const [theProduct, setTheProduct] = useState<Product>({
        id: id === "0" ? "00000000-0000-0000-0000-000000000000" : createId!==undefined ? "00000000-0000-0000-0000-000000000000" : id,
    });
  
    
 */
    const [theProduct, setTheProduct] = useState<Product|null>(null)
    const[theItem, setTheItem] =useState<InvoiceItem>()



    useEffect(()=> {
        subscribe("OrganizationChange", (data: any) => history.push("/products")  )

        if (theProduct === null&&the_id!=="0") {
            setisLoading(true)
            axios.get(AuthService.DATA_URL + 'integration/item/'+userOrganisation.id+"/id/" + the_id,
                {headers: authHeader()})
                .then((response) => {
                    console.log(response.data)
                    setTheProduct(response.data);
                    setTheItem(response.data);
                    form.setFieldsValue({
                        title: response.data ? response.data.name : "",
                        description: response.data ? response.data.description : "",
                        unit: response.data ? response.data.unit : "",
                        vatRate: response.data ? response.data.vatRate : "",
                        currency: response.data ? response.data.currency : "",
                        country: response.data ? response.data.country : "",
                        countryCode : response.data ? response.data.country : "",
                        eanCode : response.data ? response.data.country : "",
                        priceForClient : response.data ? response.data.price : "",
                        purchasePrice: response.data ? response.data.purchasePrice : "",
                        productType: response.data ? response.data.type : ""


                    });

                   // setisProductVal(response.data.type==="S"? true : false)

                    setisLoading(false)
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        AuthService.logout();
                        history.push("/login");
                        window.location.reload();

                    }
                    if(error.response.status===404){
                        console.log("notFound")
                    }

                })
        }
        else{
            if(theProduct===null&&the_id==="0"){
                setTheProduct({id:"0", integrationSpecific: {countryCode: "SI"}})
            }
        }
        return () => {
            unsubscribe("OrganizationChange", (data: any) => history.push("/products") );
        }


    });




    const showval=(v: any)=>{
        console.log("v: ",v);
        console.log("FieldValue: ",form.getFieldValue("productType"))
        setisProductVal(v);
        console.log("theProduct: ", theProduct)

    }

    const saveItems: MenuProps['items'] = [
        { key: "1",  label: <span>{t("save_and_stay")}</span> },
        { key: "2",  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        onFinish(null, e.key)

    }

    const OnChange=(values: any)=>{
        //console.log("On change form: ", theProduct);
        //console.log(form.getFieldValue("planPaydate"))
        setTheProduct(prev => ({
            ...prev,
            name: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
            type: form.getFieldValue("productType"),
            unitOfMeasurement: form.getFieldValue("unit"),
            vatRate: form.getFieldValue("vatRate"),
            currency: form.getFieldValue("currency"),
           // country: form.getFieldValue("country"),
            integrationSpecific: {countryCode : "SI"},
            priceForClient :form.getFieldValue("priceForClient"),
            purchasePrice :form.getFieldValue("purchasePrice"),
            price :form.getFieldValue("purchasePrice"),
        }));

        setTheItem(prev => ({
            ...prev,
            name: form.getFieldValue("title"),
            description: "", //form.getFieldValue("description"),
            type: form.getFieldValue("productType"),
            unitOfMeasurement: form.getFieldValue("unit"),
            vatRate: form.getFieldValue("vatRate"),
            currency: form.getFieldValue("currency"),
            integrationSpecific: {countryCode : "SI"},
           // country: "SI"//form.getFieldValue("country"),
        }));

    }



    const onFinish = (values: any, goto?: string|undefined) => {
        setisLoading(true);

        console.log("theProduct: ", theProduct)
        if(theProduct?.id ==="0"){
            
            const str=AuthService.DATA_URL+"integration/item/"+userOrganisation.id
            console.log("post str: ", str)
            console.log("theItem: ", theItem)

   
            
            axios.post(AuthService.DATA_URL+"integration/item/"+userOrganisation.id,
                theItem,{headers: authHeader()})
                .then((response) =>{
                    console.log("response.data: ", response.data.data[0])
                    if(aftercreate){
                        aftercreate(response.data.data[0])
                    }
                    else{
                        if(parseInt(goto? goto: "2")===1){
                            history.push("/products/"+response.data.data[0].integrationSpecificId)
                        }
                        else{
                            history.push("/products")
                        }
                    }

                    setisLoading(false);
                })
           
           
             
            
            
            
        }else{
            
            /*
            axios.put(AuthService.DATA_URL+'Products/'+id,
                theItem,{headers: authHeader()})
                .then()
                .then((response) =>{
                    if(parseInt(goto? goto: "2")===1){
                        history.push("/products/"+theProduct?.id)
                    }
                    else{
                        history.push("/products")
                    }
                    setisLoading(false);
                })


             */
        }
        console.log(theProduct);
    }

    const goBack=()=>{
        if(aftercreate)
        {
            aftercreate(null);
        }
        else{
            history.push("/products");
        }
    }



    const handleDelete=()=>{
        const { confirm } = Modal;
        confirm({
            title: t('Delete_warning'),
            icon: <ExclamationCircleFilled />,
            content: t("Delete_text"),
            okText: t('Yes'),
            okType: 'danger',
            cancelText: t('No'),
            onOk() {
                axios.delete(AuthService.DATA_URL+"integration/item/"+userOrganisation.id+"/"+theProduct?.integrationSpecificId
                    ,{headers: authHeader()})
                    .then((res:any) =>{
                            console.log(res)
                            if(res.status.toString()==="204"){
                                Modal.error({
                                    title: t("Delete_error_title"),
                                    content: t("Contractor_delete_error_text"),
                                });
                            }
                            else{
                                message.success(t("Done"));
                                history.push("/products");
                            }
                        }
                    );


            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    
    
   


    return( <Spin spinning={isLoading}>


        <h1>{the_id!=="0"? theProduct?.name :  t("new_product")}</h1>

       
        <Form
            form={form}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            onFinish={onFinish}
            onChange={OnChange}
            style={{ maxWidth: "600" }}
            
            initialValues={{
                /*

                ["productType"]: theProduct?.type? theProduct?.type : ""
                ["title"]: theProduct?.name,
                ["unit"]: theProduct?.unitOfMeasurement,
                ["vatRate"]: theProduct?.vatRate,
                ["currency"]: theProduct?.currency,
                ["priceForClient"] : theProduct?.price,
                ["purchasePrice"] : theProduct?.purchasePrice
                
                 */

            }}
            
             


        >
            <Row>
                <Col span={aftercreate? 24 : 8}>
                    <Form.Item
                        name="title"
                        label={t("Naziv")}
                        rules={[{ required: true }, { type: 'string', warningOnly: true }]}
                    >
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item
                        name="productType"
                        label={t("Vrsta")}
                    >
                        <Select
                            options={[
                                { label: t("goods"), value: "goods" },
                                { label: t("material"), value: "material" },
                                { label: t("semifinishedProduct"), value: "semifinishedProduct" },
                                { label: t("product"), value: "product" },
                                { label: t("services"), value: "services" },
                                { label: t("advancePayment"), value: "advancePayment" },
                                { label: t("advancePaymentServices"), value: "advancePaymentServices" },
                            ]}
                           
                            onChange={(e)=>OnChange(e)}
                            //onChange={(e) => ShowContractorDoc(e)}
                        />

                    </Form.Item>

                    <Form.Item name="unit" label="Ед. изм.">
                        <Select
                            options={[
                                { label: "шт", value: "PCE" },
                                { label: "час", value: "HUR" },
                                { label: "килограмм", value: "KGM" },
                                { label: "метр", value: "MTR" },
                                { label: <span>м<sup>3</sup></span>, value: "MTQ" }
                            ]}
                            onChange={(e)=>OnChange(e)}
                            //onChange={(e) => ShowContractorDoc(e)}
                        />
                    </Form.Item>



                    <Form.Item name="vatRate" label="НДС">
                        <Select
                            options={
                                [
                                    { label: "22%", value: "standard" },
                                    { label: "9.5%", value: "reduced" },
                                    { label: "8%", value: "flat" },
                                    { label: "5%", value: "lower" },
                                    { label: "Exempted 0%", value: "exempted" },
                                    { label: "Non-taxable 0%", value: "non-taxable" },
                                ]}
                            //onChange={SavePosition}
                            //onChange={(e) => ShowContractorDoc(e)}
                        />
                    </Form.Item>
                    <Form.Item name="currency" label="Валюта">
                        <Select
                            showSearch={true}
                            options={

                                currencies.currencies.map((item, i) => (
                                    {
                                        label: item.code+" ("+item.symbol+")",
                                        value: item.code
                                    }
                                ))
                        }
                            onChange={(e)=>OnChange(e)}
                        />
                    </Form.Item>
                    <Form.Item name="priceForClient"   label={t("Price")}  >
                        <InputNumber value={theProduct?.price}
                                     onChange={(e)=>OnChange(e)}
                                     /*suffix="€"*/ />
                    </Form.Item>
                    <Divider/>
                    <Row>
                        <Col span={6}>
                            <Button className={"emptyButton"} onClick={()=>{ goBack()}} style={{}}>
                                {t("Ne_shranite_sprememb")}
                            </Button>
                        </Col>


                    {
                        aftercreate?
                        <Col span={6}>
                            <Button className={"orangeButton"}
                                    onClick={()=> {
                                        onFinish(null, "3")
                                    }}
                                    style={{ float:"right",/*marginTop:"25px", marginRight:"50px",*/ }}>
                                {t("Shraniti")}
                            </Button>
                        </Col>

                            :
                            <Col span={6} offset={1}>

                                <Space>
                                    
                               
                            <Dropdown menu={{'items': saveItems, onClick: handleSaveOption }} >
                                <Button className={"orangeButton"}
                                        onClick={()=> {
                                            onFinish(null, "2")
                                        }}
                                        style={{ float:"right",/*marginTop:"25px", marginRight:"50px",*/ }}>
                                    {t("Shraniti")}

                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                                <Dropdown menu={{'items': [{ key: '1',  label: <Button type="primary" danger>{t("Delete")}</Button> }], onClick: handleDelete }} >
                                    <Button type="text">{t("More")}..</Button>
                                </Dropdown>

                                </Space>

                            </Col>
                    



                    }
                    </Row>



                </Col>



                <Col>

                </Col>
            </Row>


        </Form>
    </Spin>)
}