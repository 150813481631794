import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Badge, Button, Checkbox, DatePicker, Input, Select, Space, Table, Tooltip} from 'antd';


interface CompanySearchProps{
    afterChange? : (value: any)=>void
}


const CompanySearchComponent = ({afterChange} : CompanySearchProps) => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();


    if (!user) {
        // history.push("/login");
    }
    const {t} = useTranslation();

    const [showFilterBadge, setshowFilterBadge] = useState(false)

    const[_filter, setFilter] = useState<any>({
        searchText: "",
    })



    return <>
        <Input.Search className={"emptyB"}
                      placeholder={t("Search_for_company")}
                      onSearch={(value) => {
                          if(afterChange) afterChange(value)  
                          setFilter({
                              searchText: value
                          });
                      }}
                      onChange={(e) => {
                          if(afterChange) afterChange(e.target.value)
                          setFilter({
                              searchText: e.target.value
                          });

                      }}


        />
    </>
}


export default CompanySearchComponent
