import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Button, Spin} from "antd";
import * as AuthService from "../../../Services/auth-service";
import RacunPage from "../../Racun";
import { toCanvas} from "html-to-image";
import { Col, Row} from "antd";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import DocPosition from "../../../types/DocPosition";
import {ServerdateToPoints} from "../../../utils/helpers";
import dayjs from "dayjs";
import i18n from "i18next";
import InvoiceItem from "../../../types/invoiceItem";
import invoicePosition from "../../../types/DocPosition";
import bankAccount from "../../../types/BankAccount";


interface RacunProps{
    docId? : string | undefined;
    ContractorId?: string;
    RequestId? : string;
    documentDate? : string;
    payementDate? : string;
    documentNumber? : string;
    documentType? :number;
    forceReload? : boolean;
    isLoaded? :  (data: any) => void;
    ratio? : number;
    showmode? : string|undefined;
    lang? : string;
    bankAccount? : bankAccount | undefined
    positions? : invoicePosition[]
}

const ShowDocPage = ({ docId, ContractorId, documentNumber, documentType, RequestId, documentDate, payementDate, forceReload, isLoaded, ratio, showmode, lang,bankAccount, positions} : RacunProps) => {

    const history= useHistory();
    const user = AuthService.getCurrentUser();
    if(!user){
        history.push("/login");
    }


    const ref = useRef<HTMLDivElement>(document.createElement("div"));

    const [_forceReload, set_forceReload] = useState(forceReload);
    const [docPositions, setDocPositions] = useState<invoicePosition[]>([]);
    const[_documentNumber, set_documentNumber]=useState("");

    const[_documentType, set_documentType]=useState(10);
    const[_contractorId, set_contractorId]=useState(ContractorId);



    const handleIsLoad =(isLoaded : boolean) =>{
        //console.log("loading content...")
        if(isLoaded){
            // console.log(ref.current);
            const _ratio = ratio? ratio : 0.3;
            toCanvas(ref.current, {pixelRatio: _ratio})
                .then(function (canvas) {
                    const element = document.getElementById("imgContainer");
                    // @ts-ignore
                    element.innerHTML = '';
                    // console.log("element.innerHTML: ", element?.innerHTML)
                    // @ts-ignore
                    element.appendChild(canvas);
                    //   console.log("element.innerHTML after appendChild: ", element?.innerHTML)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });

        }
    }
    useEffect(()=>{
        set_forceReload(forceReload);
        set_contractorId(ContractorId);
        const htmlCont = document.getElementById("htmlContainer");


        const RacunCont = document.getElementsByClassName("Racun_main");//Racun_main
        if(RacunCont) {
            if (ref.current) {
            }
        }
        if(positions){
            //console.log("Show doc positions: ", positions)
            setDocPositions(positions);
        }

        if(documentNumber!==undefined && documentNumber!==null){
            set_documentNumber(documentNumber);
        }

        if(showmode==="preview"){
            axios.get(AuthService.DATA_URL+'docs/get-doc-dto?id='+docId, {headers: authHeader()})
                .then((response)=>{
                    console.log("shoedoc get-dto", response.data)
                    setDocPositions(response.data.docPositions);
                    set_contractorId(response.data.contractorId);
                })

        }
        if(documentType){

            set_documentType(documentType);


            if(documentType===10){
                const RacunCont = document.getElementsByClassName("Racun_main");//Racun_main
                if(RacunCont) {
                    if (ref.current) {
                    }
                }
            }
            if(documentType===11){
                const SauftragCont = document.getElementsByClassName("Sauftrag_main");
                if(SauftragCont) {
                    if (ref.current) {
                        //console.log("rendering Sauftrag_main");
                        //console.log(ref.current.children[0].children);
                    }
                }

            }

        }
    }, [forceReload, positions, documentNumber, documentType, showmode, ContractorId])

    const[myCompany, setmyCompany]= useState<any>();

    useEffect(()=>{
        if(!myCompany)
            axios.get(AuthService.DATA_URL+'organization', {headers: authHeader()})
                .then((response) => {
                    console.log("organisation", response.data)
                    setmyCompany(response.data);
                    //console.log("preview docPositions: ", docPositions);
                })
                .catch(()=>{
                    setmyCompany({id:"sdfsdf"});

                    console.log(user)
                })
            
            ;
    }, )


    const _mycompany = myCompany ? myCompany : null;






    const element = document.getElementById("imgContainer");
    if(element && element.children.length===0 && !_mycompany){
        // return <Spin> </Spin>
    }else{
        if(element){
            if(isLoaded){
                isLoaded(true);
            }
        }
    }





    return (
        <div >

            <div style={{position: "absolute", top :"-4000px"}}>


                <div ref={ref} id={"htmlContainer"}>
                    {_documentType===10 ?
                        <RacunPage ContractorId={_contractorId ? _contractorId : _mycompany ? _mycompany.id : undefined}
                                   RequestId={RequestId ? RequestId : undefined}
                                   documentDate={documentDate}
                                   payementDate={payementDate}
                                   documentNumber={_documentNumber}
                                   forceReload={_forceReload} isLoaded={handleIsLoad}
                                   positions={docPositions}
                                   bankAccount={bankAccount}
                                   lang = {lang}
                        />
                        :
                        <RacunPage ContractorId={_contractorId ? _contractorId : _mycompany ? _mycompany.id : undefined}
                                   RequestId={RequestId ? RequestId : undefined}
                                   documentDate={documentDate}
                                   payementDate={payementDate}
                                   documentNumber={_documentNumber}
                                   forceReload={_forceReload} isLoaded={handleIsLoad}
                                   positions={docPositions}
                                   bankAccount={bankAccount}
                                   lang = {lang}
                        />                    }
                </div>



            </div>
            <Row>


                <Col span={12} >
                    <div
                        id={"imgContainer"} style={{minWidth: "350px", minHeight: "550px",
                        //padding:"20px", textAlign: "center",
                        //boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
                        //border: "1px solid #e6e6e6",
                        //backgroundColor: "#fff"
                    }}>
                        <Spin style={{marginTop: "50%", height: "40px"}}> </Spin>
                    </div>

                </Col>
            </Row>



        </div>
    );
};
export default ShowDocPage;