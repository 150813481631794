import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Button, Col, Form, Input, List, Row, Select} from "antd";
import DocPositionForm from "./positionForm";
import {DeleteOutlined} from "@ant-design/icons";
import {DATA_URL} from "../../../Services/auth-service";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import DocPosition from "../../../types/DocPosition";
import {useTranslation} from "react-i18next";
import InvoiceItem from "../../../types/invoiceItem";
import {vatRateToNumber} from "../../../utils/helpers";


interface PositionsListProps {
    DocId?: string;
    ReqId?: string;
    positions? : InvoiceItem[];
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: () => void;
    informParent? : (postions?: DocPosition[]) =>void;
    isDisabled? : boolean;
    

}

const PositionsList = ({DocId, ReqId, positions, forceReload, deleteFunc, informParent, isDisabled}: PositionsListProps) => {

    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
      //  history.push("/login");
    }



    const [posNo, setposNo] = useState(1);
    const [positionsList, setpositionsList] = useState<any[]>([]);
    const [listLoading, setslistLoading] = useState(false);

    const handleAddedPosition = (values: any)=>{
        console.log("handleAddedPosition values: ", values );

        console.log("handleAddedPosition positionsList: ", positionsList );
        const index = positionsList.indexOf(positionsList.find(p=>p.id===values.id), 0);
        if(positionsList[index]){
            positionsList[index].name=values.name;
            positionsList[index].quantity=values.quantity;
            positionsList[index].mass=values.mass;
            positionsList[index].price=values.price;
            positionsList[index].vatRate= values.vatRate;
            positionsList[index].integrationSpecificId=values.integrationSpecificId;
        }
        if(informParent){
            informParent(positionsList.map((p)=>({
                name : p.name,
                quantity : p.quantity,
                mass : p.mass,
                price : p.price,
                vatRate: p.vatRate,
                integrationSpecificId : p.integrationSpecificId
            })));
        }
        //console.log("values: ", positionsList );
        ///addPosition();
    }


    const addPosition = (defaults? : any)=>{

        setslistLoading(true);

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
//        console.log("position list defaults: ", defaults)
        console.log("isDisabled: ", isDisabled)
        positionsList.push({
            id : defaults? defaults.keyStr===" "? posNo : defaults.keyStr : posNo,
            keyStr: defaults? defaults.keyStr : posNo,
            quantity : defaults ? defaults.quantity: 0,
            vatRate : defaults ? defaults.vatRate : 0,
            price: defaults ? defaults.price : 0,
            name: defaults ? defaults.name : "",
            title : "sdfsdf",
            integrationSpecificId : defaults ? defaults.integrationSpecificId : " ",
            description:
                <div>
                    <DocPositionForm keyStr={posNo} 
                                     name={defaults? defaults.name : ""}
                                     quantity={defaults ? defaults.quantity : 0}
                                     vatRate={defaults ? defaults.vatRate : ""}
                                     price={defaults ? defaults.price : 0}
                                     integrationSpecificId={defaults ? defaults.integrationSpecificId : undefined}
                                     forceReload={true} deleteFunc={deletePos} addFunc={handleAddedPosition} 
                                     isDisabled={isDisabled}
                    
                    />
                </div>
        });
        setpositionsList(positionsList);
        console.log(positionsList);
        //setposNo(posNo+1);
        //console.log("after adding posId:", posNo);
        setslistLoading(false);
    }


    const renderItem = (item: any) =>{
        //console.log("positions list item: ", item)

        return(
            <List.Item key={"sdfsdf" + item.id?  item.id.toString(): item.integrationSpecificId.toString()}
                       style={{borderBottomWidth: 0, padding: "0px"}}
            >
                <List.Item.Meta
                    //bordered={false}
                    description={
                        <div>
                            <DocPositionForm keyStr={item.id}
                                             name={item.name}
                                             integrationSpecificId={item.integrationSpecificId}
                                             quantity={item.quantity}
                                             vatRate={item.vatRate}
                                             price = {item.price}
                                             forceReload={true}
                                             deleteFunc={deletePos}
                                             isDisabled={isDisabled}
                                             addFunc={handleAddedPosition} />
                        </div>

                    }
                />
            </List.Item>

        )
    }


    const deletePos =(poskey : number) =>{

        var d=new Date();
        var secondsStart = d.getTime();
        setposNo(secondsStart);
        //  console.log("delete poskey:",poskey,  " posId: ", posNo);

        setslistLoading(true);

        const index = positionsList.indexOf(positionsList.find(p=>p.id===poskey), 0);
        //console.log("position to delete: ", positionsList[index]);

        if (index > -1) {
            positionsList.splice(index, 1);
        }
        //console.log("delete: ", poskey, "posId", posNo);
        setpositionsList(positionsList);
        //console.log(positionsList);
        var d=new Date();
        var secondsStart2 = d.getTime();
        setposNo(secondsStart2);

        if(informParent){
            informParent(positionsList.map((p)=>({
                name : p.name,
                quantity : p.quantity,
                mass : p.mass,
                price : p.price,
                vatRate: p.vatRate,
                integrationSpecificId : p.integrationSpecificId
            })));
        }

        setslistLoading(false);
    }



    useEffect(()=>{

        if(positions) {
            setpositionsList(positions);
        }


    }, [])





    //console.log("rendering posId: ", posNo);


    return(
        <div style={{width: "100%"}}>
            <Row  gutter={8}>
                <Col span={11} style={{marginBottom: "4px", paddingLeft: "8px" }}>{t("Naziv_storitva_blaga")}
                </Col>
                <Col span={3}>{t("Količina")}
                </Col>
                <Col span={3}>{t("Davek")}
                </Col>
                <Col span={4}>{t("Cena")}
                </Col>
            </Row>

            {listLoading ? "" :

                <div>
                    <List
                        style={{width: "100%", borderBottomWidth: "0"}}
                        //loading={listLoading}
                        bordered={false}
                        locale={{ emptyText: <span> </span> }}
                        dataSource={positionsList}
                        renderItem={(item: any, index) => (renderItem(item)
                        )}
                    />

                </div>

            }
            {/*
                positionsList.length>0 ?"" :
                <Button onClick={addPosition}>Dodajte vrstico</Button>
                */
            }

            {isDisabled? "" 
                :
                <Button onClick={()=>addPosition(undefined)}>{t("Dodajte_vrstico")}</Button>
            }




        </div>

    )

}

export default PositionsList;