import React from 'react';
import { lazy, Suspense } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import ClientDashboard from "./Dashboard/Dashboard";
import ProductsList from "./Products/ProductsPage";
import DocList from "./Docs/DocsList";
import CounterPartList from "./Counterparts/CounterPartsList";
import Settings from "./Settings/SetingsPage";
import EditDoc from "./Docs/Components/EditDocPage";
import CreateContractor from "./Counterparts/Components/CreateComponent";
import ProductDetailPage from "./Products/Components/ProductDetails";
import DisplayReceived from "./Docs/Components/DisplayReceivedDocPage";
import EditContractorComp from "./Counterparts/Components/EditComponent";
import OnboardingPage from "./Onboarding";
import SuperTablePage from "./SuperTable/SuperTable";
import WarehousePage from './Warehouse/WarehousePage';
import WarehouseItemDetails from './Warehouse/ItemPage';


interface Props {
    component?: React.ComponentType | null
    path?: string | null
}






export const Routing = () => {
    
    

    return (
        <Suspense fallback={<div>Loading...</div>}>
   <BrowserRouter>
            <Switch>
               
                <Route key={1} exact path="/login" component={Login} />
                <Route key={0} exact path="/logout" component={Logout} />
                <Route key={2} exact path="/dashboard" component={ClientDashboard} />
                <Route key={21} exact path="/" component={ClientDashboard} />
                <Route key={3} exact path="/products" component={ProductsList} />
                <Route key={31} path="/products/:id" component={ProductDetailPage} />



                <Route key={4} exact path="/docs" component={DocList} />
                <Route key={41} exact path="/docs/:id" component={EditDoc} />
                <Route key={42} exact path="/docs/received/:id/:status" component={DisplayReceived} />



                <Route key={5} exact path="/counterparts" component={CounterPartList} />

                 
                <Route key={14} exact path="/counterparts/new" component={CreateContractor} />
                <Route key={15} path="/counterparts/editcontractor/:id" component={EditContractorComp} />




                <Route key={6} exact path="/settings" component={Settings} />


                <Route key={698} exact path="/supertable" component={SuperTablePage} />



                <Route key={61} exact path="/onboarding" component={OnboardingPage} />
                <Route key={20} exact path="/warehouse" component={WarehousePage} />
                <Route key={21} path="/warehouse/:id" component={WarehouseItemDetails} />


                {/*<Route key={4} path="/trucks/:id" component={TrucksDetailsPage} />
                <Route key={5} exact path="/newtruck" component={newTruckPage} />


                <Route key={6} exact path="/drivers" component={DriversPage} />
                <Route key={7} exact path="/newdriver" component={CreateDriver} />
                <Route key={8} path="/drivers/:id" component={DriverDetailsPage} />




                <Route key={9} exact path="/routes" component={MyRoutesPage} />
                <Route key={10} path="/newroute" component={RouteCreatePage} />
                <Route key={11} path="/routes/view/:id" component={RouteDetailsPage} />

         


                <Route key={90} exact path="/deals" component={DealsPage} />
                <Route key={91} path="/deals/:id" component={DealsDetailPage} />


                <Route key={40} exact path="/products" component={ProductsPage} />
                <Route key={41} path="/products/:id" component={ProductDetailPage} />


                <Route key={12} path="/routes/edit/:id" component={RouteEditPage} />



                <Route key={13} exact path="/contractors" component={ContractorsPage} />
                <Route key={14} exact path="/contractors/new" component={CreateContractorPage} />
                <Route key={15} path="/contractors/editcontractor/:id" component={EditContractorPage} />




                <Route key={16} exact path="/docs" component={DocsPage} />
                <Route key={17} exact path="/showdoc" component={ShowDocPage} />
                <Route key={171} exact path="/docs/:id" component={CreateDocPage} />





                <Route key={18} exact path="/transactions" component={TransactionsPage} />
                <Route key={181} exact path="/transactions/dds" component={DDSPage} />
                <Route key={19} exact path="/settings" component={SettingsPage} />

                <Route key={1978}  path="/rev_checkout" component={CheckOutPage} />


                <Route  key={62} exact  path="/return" component={Return} />
                <Route  key={61} exact path="/checkout" component={CheckoutForm} />
*/}

            </Switch>
   </BrowserRouter>
        </Suspense>
        
    );
};