import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {Card, Col, Divider, List, Row, Space, Timeline, Modal} from "antd";
import LangSelect from "../../CommonComponents/LangSelect";
import {
    ArrowDownOutlined,
    LogoutOutlined,
    FileTextOutlined,
    GoldOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    FileOutlined,
    UploadOutlined, DownloadOutlined
} from '@ant-design/icons';
import i18n from "i18next";
import axios from "axios";
import authHeader from "../../Services/auth-header";
import {DATA_URL} from "../../Services/auth-service";
import UploadDoc from "../../CommonComponents/UploadDoc";


const ClientDashboard = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    
   
    const[parent, setParent] = useState<any>(null)


    const tt: any = localStorage.getItem("userlanguage")===null ? "" : localStorage.getItem("userlanguage");
    const {t} = useTranslation('translation', { lng: tt });



    if(!user){
        //history.push("/login");
    }

    /*
    useEffect(()=>{
        if(parent===null){
           const current_org= AuthService.getCurrentOrganisation();
           if(current_org===null){
               history.push("/login")
           }
           else{
               console.log("Daashboard current_org", current_org)
               axios
                   .get(DATA_URL + "organization/"+current_org.parentId, { headers: authHeader()
                   })
                   .then((org_response) => {
                       setParent(org_response.data);
                       console.log("parent organizations: ", org_response.data)
                   })
                   .catch(function (error) {
                       if (error.response.status === 401) {
                           AuthService.logout();
                           history.push("/login");
                           window.location.reload();

                       }
                       if(error.response.status===404){
                           console.log("notFound")
                       }

                   })
               
           }
           //console.log("current_org: ", current_org)

        }
        setParent({})
    },[])
    
     */

    const [isModalOpen, setIsModalOpen] = useState(false);


    const data = [
        {
            title: <span style={{cursor: "pointer"}} onClick={()=>history.push("/docs/createdoc")}>
                {t("Issue_an_invoice_to_a_customer")}
            </span> ,
            icon: <PlusCircleOutlined style={{fontSize: "24px", color: "green"}} />,
            description: t("You_can_also_create_a_new_invoice_from_the_Documents_section")
        },
        {
            title: <span style={{cursor: "pointer"}} onClick={()=>setIsModalOpen(true)}>
                {t("Upload_incoming_invoice")}
            </span> ,
            icon: <MinusCircleOutlined style={{fontSize: "24px", color: "#7918e6"}} onClick={()=>setIsModalOpen(true)} />,
            description: t("Upload_invoice_scan"),
            href: '',
        },
        {
            title: t("Upload_a_statement"),
            icon: <FileTextOutlined style={{fontSize: "24px", color: "#265a96"}} />,
            description: t("Upload_pdf_or_csv"),
            href: '',
        },
        {
            title: t("Send_us_documents"),
            icon: <FileOutlined style={{fontSize: "24px"}} />,
            description: t("Contracts_annexes_and_other_documents"),
            href: '',
        },
    ];







    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const handleModalOk=()=>{
        setIsModalOpen(false);
    }

    return <>
    <h1>{t("Good_afternoon")}</h1>
        <p>{t("Welcomed_by")} {parent?.legalName}</p>
        <Row>
            <Col span={14}>
                <Card title={t("What_do_you_want_to_do")}>

                    <List
                        itemLayout="horizontal"
                        size={"small"}
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={item.icon}
                                    title={item.title}
                                    description={item.description}
                                    
                                />
                            </List.Item>
                        )}
                    />
                    
                </Card>
                <Divider />
                <Card title={t("Tasks")}>
                    {t("No_new_tasks")}
                </Card>
            </Col>
            
            <Col span={9} offset={1}>
                <Row>
                    <Col span={24}>
                        <Space>
                            <span>{t("Interface_language")}:</span>
                            <span><LangSelect  /></span>
                        </Space>
                    </Col>
                </Row>
                
                <Divider/>
                    
                <h3 style={{marginBottom: "30px"}}>
                    {t("Events_in_June")}:
                </h3>
                <Timeline
                    style={{width: "50%"}}
                    mode={"left"}
                    items={[
                        {
                            color: '#999',
                            label: <span style={{color: "#999"}}>15.06.2024</span>,
                            children: <div style={{minWidth: "300px", color: "#999"}}><b>{t("Salary_payment")}</b>
                            <br/>
                                <span>{t("Small_checklist")}:
                                <ol>
                                    <li>
                                        {t("Has_anyone_had_a_salary_increase")}
                                    </li>
                                    <li>
                                        {t("Is_the_account_balance_sufficient")}
                                    </li>
                                </ol>
                                </span>
                            </div>,
                        },
                        {
                            color: '#999',
                            label: <span style={{color: "#999"}}>25.06.2024</span>,
                            children: <div style={{minWidth: "300px", color: "#999"}}><b>{t("Statehood_Day")}</b><br/>
                            <span>{t("Its_a_day_off_in_Slovenia")}</span>
                            </div>,
                        },
                        {
                            label: '30.06.2024',
                            children:<div style={{minWidth: "300px"}}><b>{t("Bank_statement")}</b><br/>
                                <span>{t("Generate_a_bank_statement_for_June_and_upload_it_for_us")}</span>
                            </div>,
                        },
                    ]}
                />
           
                
            </Col>
            
        </Row>

        <Modal open={isModalOpen}
               onOk={handleModalOk}
               title={t("Prenesite_dokument")}
               centered
               width={580}
               onCancel={handleModalCancel}
            //footer={[]}
        >

            <UploadDoc objectId={null} objectMode={10} vrstaDoka={100}/>
        </Modal>
       
    
    </>

}

export default ClientDashboard