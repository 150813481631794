import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Col, ConfigProvider, Input, Row, Space, Table, TableProps, TableColumnsType} from "antd";
import CompanyEventAcitivityComponent from "./Components/CompanyEventAcitivityComponent";
import dayjs from "dayjs";
import MonthSelectComponent from "./Components/MonthSelect";
import CompanySearchComponent from "./Components/CompanySearch";
import axios from "axios";
import {DATA_URL} from "../../Services/auth-service";
import {dateForServerFromDate, ServerdateToPoints} from "../../utils/helpers";
import SuperTableContent from "../../types/SuperTableContent";





const SuperTablePage = () => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();


    if (!user) {
       // history.push("/login");
    }
    const {t} = useTranslation();




    const [pageloading, setpageloading] = useState<boolean>(false)

    const [companySearchText, setcompanySearchText] = useState("")



    const [companyActivity, setcompanyActivity] = useState<SuperTableContent[]>([])
    


    const [startDate, setStartDate] = useState(dateForServerFromDate(dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1))));
    const [endDate, setEndDate] = useState(dateForServerFromDate(dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1, 0))));
    const [organisationId, setorganisationId] = useState("666462f81797f5621706a175");
    
    
    const [columns, setColumns] = useState<any[]>([]);


    const onChange: TableProps<SuperTableContent>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    

    const cols: any[]=[]
    cols.push({
        title: t('Stranka'),
        key:"0",
        dataIndex:'legalName',
        width: 300,
        fixed: 'left',
        render: (text:any, record: any) => {
            return (
                <>
                    {record.legalName}
                </>

            )}
    })

    
    const [summary, setSummary] = useState<any[]>([])
    
    const fetchData = (companymane? : string |undefined)=>{

        setpageloading(true);
        axios.get(DATA_URL + "event/content/"+organisationId+"/"+startDate+"/"+endDate//+id
            ,{ headers: {Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjI4YmI4NGYwMmNiNjkyYThlNGMwM2MiLCJlbWFpbCI6Im1lQG1vcm96b3Yuc2kiLCJleHAiOjE3MjE2NzU5MTEyMTcsImlhdCI6MTcyMTY2ODcxMX0.c_nT1G9wYpMm4vd0Ctg01rlVYkLBKr4My_qAZF4JJbo'}}
        )
            .then((response)=> {

                const theData: SuperTableContent[] =
                    companymane?
                        response.data.filter((row: any) => row.legalName.toLowerCase().includes(companymane.toString().toLowerCase()))
                            :
                        response.data;

                setcompanyActivity(theData);
                console.log("theData: ", theData)

                
                theData.forEach((datarow: any, ridx: number)=>{
                    //разбираем строчку компании
                    datarow.row.forEach((event: any, idx: number)=>{
                        //разбираем каждый элемент из row в строчке компании
                       
                        //создаем структуру summary
                        if(idx>summary.length-1){
                            if(event)
                            summary.push({eventName: event.activity, value: 0})
                        }
                    })
//                    summary[ridx].values.push(datarow.row[ridx].status)
                   // console.log("row for summ: ", datarow.row)
                    
                    
                })
                
                //теперь заполним summary
                summary.forEach((summ: any, idx)=>{
                    var closed=0;
                    theData.forEach((datarow: any)=>{
                        if(datarow.row[idx]){
                            if(datarow.row[idx].status==="closed") closed=closed+1
                        }
                    })
                    
                    const ttt=theData? theData.length : 0;
                    const prcnt=ttt/100;
                    summ.value=((closed/prcnt)).toFixed(2);
                })
                
                console.log("summary", summary)
                
                
                setpageloading(false);
            });
        
    }

    
    useEffect(()=>{

        if(columns.length===0 ){
            setpageloading(true);
            fetchData(companySearchText)

            axios.get(DATA_URL + "event/header/"+organisationId+"/"+startDate+"/"+endDate
                ,{ headers: {Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjI4YmI4NGYwMmNiNjkyYThlNGMwM2MiLCJlbWFpbCI6Im1lQG1vcm96b3Yuc2kiLCJleHAiOjE3MjE2NzU5MTEyMTcsImlhdCI6MTcyMTY2ODcxMX0.c_nT1G9wYpMm4vd0Ctg01rlVYkLBKr4My_qAZF4JJbo'}}
            )
                .then((response)=>{
                    const firstLine:any = {events: response.data} || {};
                    var evlen=response.data[0].activities.length;

                    response.data.forEach((_event: any, eidx: number)=>{
                        const  activityCols:any[]=[];
                        var activityColidx=0;
                        _event.activities.forEach((_activity: any, aidx: number)=>{
                            activityColidx=activityColidx+1;
                            const activityCol = {
                                className: activityColidx===1 ? "leftCol" : "",
                                title: _activity.name
                                    //+(summary[evlen*eidx+aidx] ? " ("+summary[evlen*eidx+aidx].value+"%)" : "...")
                                ,
                                width: 250,
                                key: _activity._id.toString(),
                                render: (text:any, record:any) => {
                                    return(
                                        <div>
                                            {
                                                record.row[evlen*eidx+aidx]?
                                                    <CompanyEventAcitivityComponent
                                                        organisationId={"sdfsdf"}
                                                        activity = {(record.row[evlen*eidx+aidx]? record.row[evlen*eidx+aidx] : undefined)}
                                                        //activity = {record.events[event_key].activities[activity_key]}
                                                    />
                                                    :
                                                    ""
                                            }
                                        </div>)
                                }}
                            activityCols.push(activityCol)
                        })
                        const eventCol = {
                            className: "eventColumn",
                            key: eidx.toString(),
                            title:
                                <div style={{color: "#000", fontSize: "14px", textAlign: "center", fontWeight: 600}}>
                                    {_event.name}<br/>
                                    {ServerdateToPoints(_event.end)}
                                </div>,
                            children: activityCols
                        }
                        cols.push(eventCol)
                    })
                    setColumns(cols)
                    setpageloading(false);
                })
        }





    })




    
  
    
    
    const handleMonthSelect=(startDate: any, endDate: any)=>{
        setStartDate(dateForServerFromDate(startDate));
        setEndDate(dateForServerFromDate(endDate))
        setcompanyActivity([])
        setColumns([])
        
        console.log("handleMonthSelect :", dateForServerFromDate(startDate), dateForServerFromDate(endDate))
    }
    
    
    
    const handleSearch=(companymane: string)=>{
        setcompanySearchText(companymane)
        fetchData(companymane)
    }
    
    return <>
        
        <Row style={{marginBottom: "20px"}}>
            <Col span={4}>
                <MonthSelectComponent afterSelect={(start, end)=>handleMonthSelect(start, end)}/>
            </Col>
            <Col offset={1}>
                <CompanySearchComponent afterChange={(value:string)=>handleSearch(value)} />
            </Col>
            

        </Row>

       

        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerColor: "#333",
                    },
                },
            }}
        >
        
        <Table
            columns={columns}
            dataSource={companyActivity? companyActivity : undefined}
            loading={pageloading}
            scroll={{ y: 'calc(57vh)' }}
            pagination={false}
            bordered
            onChange={onChange}
            //onRow={(record, rowIndex) => {
            //    return {
            //        onClick: (event) => {history.push("/products/"+record.integrationSpecificId)}, // click row
            //   };
            //}}
        />
        </ConfigProvider>
    </>
        

}

export default SuperTablePage
