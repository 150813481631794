import React, {useEffect, useState} from 'react';

import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, MenuProps, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import "../Services/multiLang";

const LangSelect = () => {
    const {t} = useTranslation();
    const [userslang, setuserslang] = useState<string|null>(null);
    const [langIcon, setlangIcon] = useState<string|null>(null);

    
    const setLanguage=(key: string)=>{

        switch(key) {
            case "en": {
                setuserslang("English");
                setlangIcon("en")
                i18n.changeLanguage(key);
                break;
            }
            case "si": {
                setuserslang("Slovenščina");
                setlangIcon("si")
                i18n.changeLanguage(key);
                break;
            }
            case "fr": {
                setuserslang("Français");
                setlangIcon("fr")
                i18n.changeLanguage(key);
                break;
            }
            case "de": {
                setuserslang("Deutsch");
                setlangIcon("de")
                i18n.changeLanguage(key);
                break;
            }
            case "ru": {
                setuserslang("Русский");
                setlangIcon("ru")
                i18n.changeLanguage(key);
                break;
            }
            case "it": {
                setuserslang("Italiano");
                setlangIcon("it")
                i18n.changeLanguage(key);
                break;
            }
            case "uk": {
                setuserslang("Українська");
                setlangIcon("uk")
                i18n.changeLanguage(key);
                break;
            }


            default: {
                setuserslang("English");
                setlangIcon("en")
                i18n.changeLanguage(key);
                break;
            }
        }
    }
    
    
    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
//        { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
//        { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
//        { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
//        { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        i18n.changeLanguage(e.key);
        setLanguage(e.key)

       // console.log("new language is set: ", e.key)
        localStorage.setItem("userlanguage", e.key);
        i18n.changeLanguage(e.key);
       // console.log("localStorage: ", localStorage.getItem("userlanguage"))




    

    };
    
    
    useEffect(()=>{
        if(userslang===null){
            const tt: any = localStorage.getItem("userlanguage")===null ? "" : localStorage.getItem("userlanguage");
            setLanguage(tt)
            i18n.changeLanguage(tt);

           // console.log("userlanguage: ",tt);
            
        }

    })

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };

    
    return(
        <Dropdown menu={menuProps}>
        <Button>
            <Space>
                <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                {userslang}
                <DownOutlined />
            </Space>
        </Button>
    </Dropdown>
    )



}

export default LangSelect;