import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Divider, Form, Input, Modal, Popover, Select} from "antd";
import React, {useState} from "react";
import ActivityComment from "../../../types/ActivityComment";
import Activity from "../../../types/Activity";
import {dateToPoints, ServerdateToPoints} from "../../../utils/helpers";
import axios from "axios";
import {DATA_URL} from "../../../Services/auth-service";


interface ActivityCommentsProps{
    activity? : Activity | null;
    comments : ActivityComment[] ;
    afterSelect? : (value: any)=>void
}


interface Values {
    newcomment?: string;
}


const ActivityCommentsComponent=({activity,comments, afterSelect}: ActivityCommentsProps)=>{
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }


    const [formValues, setFormValues] = useState<Values>();
    
    
    const [isModalOpen, setIsModalOpen] = useState(false)


    const [form] = Form.useForm();
    
    const handleOk = (values: Values) => {
        setFormValues(values)
        comments?.push({
            content : values.newcomment,
            date: dateToPoints(new Date()),
            author: {firstName: user.user.firstName, lastName: user.user.lastName}
        })
        setIsModalOpen(false);
        
        
        if(activity?._id!=="new")
        {
            axios.post(DATA_URL+"todo/"+activity?._id,
                {content: values.newcomment}
                ,{ headers: {Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjI4YmI4NGYwMmNiNjkyYThlNGMwM2MiLCJlbWFpbCI6Im1lQG1vcm96b3Yuc2kiLCJleHAiOjE3MjE2NzU5MTEyMTcsImlhdCI6MTcyMTY2ODcxMX0.c_nT1G9wYpMm4vd0Ctg01rlVYkLBKr4My_qAZF4JJbo'}}

            )
        }
        else{
            console.log("post new todo")
        }
        
        
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }


    
    return<>
        { comments?.length>0 ? 
            <Popover title={activity?.name}
                     content={
                         <>
                             {
                                 (comments[comments.length-1]? ServerdateToPoints(comments[comments.length-1].date) : "")
                                 + " "
                                 + (comments[comments.length-1]? comments[comments.length-1].author? 
                                     comments[comments.length-1].author?.firstName+" "+ comments[comments.length-1].author?.lastName: "" : "")
                                 +": "+(comments[comments.length-1] ? comments[comments.length-1].content : "")
                             }
                         </>

                     }
            >
                <a onClick={()=> setIsModalOpen(true)} style={{borderBottom: "1px dashed", color: "#000"}}>
                    {
                        comments.length?
                            comments[comments.length-1]?
                                comments[comments.length-1].content?
                                    // @ts-ignore
                                    comments[comments.length-1].content?.length > 15 ?
                           
                            comments[comments.length-1].content?.substring(0, 13) + "..."
                            : comments[comments.length-1].content
                        :"" :"" : ""}


                </a>
            </Popover>

            :<a onClick={()=> setIsModalOpen(true)} style={{borderBottom: "1px dashed", color: "#999"}}>Add comment</a>


        }


        
        
        
        
        
        
        <Modal title={activity?.name} open={isModalOpen} 
               //onOk={handleOk} 
               okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
               onCancel={handleCancel}
               destroyOnClose
               modalRender={(dom) => (
                   <Form
                       layout="horizontal"
                       form={form}
                       name="form_in_modal"
                       //clearOnDestroy={true}
                       onFinish={(values) => handleOk(values)}
                   >
                       {dom}
                   </Form>)}

                   >
            
            <p>{activity?.description}</p>
            
            <div style={{height: "300px", overflowY: "scroll"}}>
                {
                    comments?
                        Array.isArray(comments) ?
                    comments?.map((comment,idx)=>(
                    <p key={idx}>
                    <span style={{color: "#333", fontSize: "12px"}}>
                        {ServerdateToPoints(comment.date)+ " "+ comment.author?.firstName+comment.author?.lastName+": "} 
                    </span><br/>
                        {comment.content}
                    </p>
                ))
                            :""
                :""
                
                }
                
            </div>
            
            <Divider/>
            <Form.Item
                name="newcomment"
                label="Comment"
                //rules={[{ required: true, message: 'Please input the title of collection!' }]}
            >
                <Input />
            </Form.Item>

            
        </Modal>
    
    
    
    </>
}

export default ActivityCommentsComponent