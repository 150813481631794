import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import {Tabs, TabsProps} from "antd";
import CompanySettingsPage from "./Components/CompanySetting";
import UserSettingsPage from "./Components/UserSetting";
import {useTranslation} from "react-i18next";



const Settings = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();

    const {t} = useTranslation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('tab');




    if(!user){
        history.push("/login");
    }
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Nastavitve_uporabnika'),
            children: <UserSettingsPage/>
        },
        {
            key: '2',
            label: t('Nastavitve_podjetja'),
            children: <CompanySettingsPage/>
        },

    ]

    const onChange = (key: string) => {
        //console.log(key);
    };


    return <>
        <h1>
            {t("Nastavitve")}
        </h1>
        {/* 
        <textarea style={{width:"300px"}}>
            {user.token}
        </textarea>
        */}

        <Tabs defaultActiveKey={paramValue===null ? "1" : paramValue } items={items} onChange={onChange} />

    </>

}

export default Settings