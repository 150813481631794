import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import StockMovement from '../../../types/StockMovement';

interface ItemTableProps {
    data: StockMovement[];
  }

const ItemTable = ({ data }: ItemTableProps) => {
    const {t} = useTranslation();
    const columns: ColumnsType<StockMovement> = [
        {
          title: t('Movement_date'),
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: t('Start_stock'),
          dataIndex: 'stock',
          key: 'stock',
        },
        {
          title: t('Поступления'),
          dataIndex: 'incoming',
          key: 'incoming',
        },
        {
          title: t('Списания'),
          dataIndex: 'outgoing',
          key: 'outgoing',
        },
        {
          title: t('Конечный остаток'),
          dataIndex: 'finalStock',
          key: 'finalStock',
        },
      ];
  return  <Table columns={columns} dataSource={data} />;
};

export default ItemTable;