import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import dayjs from "dayjs";
import {Button, Input, Space} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";


interface MonthSelectProps{
    afterSelect? : (startDate: any, endDate : any)=>void
}


const MonthSelectComponent = ({afterSelect}: MonthSelectProps) => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();


    if (!user) {
        // history.push("/login");
    }
    const {t} = useTranslation();

    const mapMonthNumberToString = (i: number) => {
        
        switch (i) {
            //case 0: return t("January");
            case 1:
                return t("January");
            case 2:
                return t("February")
            case 3:
                return t("March")
            case 4:
                return t("April");
            case 5:
                return t("May")
            case 6:
                return t("June")
            case 7:
                return t("July");
            case 8:
                return t("August")
            case 9:
                return t("September")
            case 10:
                return t("October");
            case 11:
                return t("November")
            case 12:
                return t("December")
            default:
                return "Месяц"

        }
    }


    const[choosedFromDate, setChoosedFromDate] = useState(
            dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)))
    const[choosedToDate, setChoosedToDate] = useState(
        
            dayjs(new Date((new Date()).getFullYear(), (new Date()).getMonth()+1, 0)))

    const[choosedMonthName, setMonthName]=useState<string>(
        mapMonthNumberToString(new Date().getMonth() + 1) + " "+new Date().getFullYear().toString())





    const changeMonnth=(i:number)=>{

        var date = new Date();
        var month:number=date.getMonth();
        var year:number=date.getFullYear();
        // console.log("choosedFromDate: ", choosedFromDate.year(), choosedFromDate.month(), 1)
        console.log("startDate: ", new Date(choosedFromDate.year(), choosedFromDate.month()+i, 1))
        console.log("endDate: ", new Date(choosedFromDate.year(), choosedFromDate.month()+1+i, 0))


        var start =dayjs(new Date(choosedFromDate.year(), choosedFromDate.month()+i, 1));
        var end = dayjs(new Date(choosedFromDate.year(), choosedFromDate.month()+1+i, 0));
        setChoosedFromDate(start)
        setChoosedToDate(end)
        
        
        if(afterSelect){
            afterSelect(start, end)
        }

        /*
        setListFilter(prev => ({
            ...prev,
            dateFrom: dateForServerFromDate(start),
            dateTo: dateForServerFromDate(end),
            selectedDates: ""
        }));
        
         */


        console.log(choosedFromDate.month()+i+1===0? 12 : choosedFromDate.month()+i+1);
        setMonthName((mapMonthNumberToString(choosedFromDate.month()+i+1===0? 12 :

            choosedFromDate.month()+i+1===13? 1 :
                choosedFromDate.month()+i+1))+" "+start.year())

    }




    return <>
    <Space.Compact block>
            <Button icon={<LeftOutlined />} onClick={()=>changeMonnth(-1)} />
            <Input disabled={true}
                   style={{ /*width: 'calc(100% - 150px)',*/ backgroundColor:"#fff", color: "#000", textAlign:"center" }}
                   value={choosedMonthName}
            />
            <Button icon={<RightOutlined/>} onClick={()=>changeMonnth(1)} />

        </Space.Compact>
    </>
    
    
}

export default MonthSelectComponent

