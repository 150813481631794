import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {Dropdown, MenuProps, Select, Space} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    CheckSquareOutlined,
    ClockCircleOutlined,
    DownOutlined,
    PlayCircleOutlined,
    WarningOutlined
} from "@ant-design/icons";
import axios from "axios";
import {DATA_URL} from "../../../Services/auth-service";


interface StatusSelectProps{
    defaultStatus? : string
    activityId? : string
    afterSelect? : (value: any)=>void
}

const ActivityStatusSelect=({defaultStatus, activityId, afterSelect}: StatusSelectProps)=>{
    const history = useHistory();

    const {t} = useTranslation();

    const user = AuthService.getCurrentUser();
    
    
    
    
    const [status, setStatus]=useState(defaultStatus)

    const setStatusIcon = (status? : string|null|undefined)=> {
        switch (status) {
            case "doing":
                return <span style={{color: "blue"}}><PlayCircleOutlined />&nbsp;{status}</span>
            case "waiting":
                return <span style={{color: "red"}}><WarningOutlined style={{color: "red"}}/>&nbsp;{status}</span>
            case "to do":
                return <span style={{color: "orange"}}><ClockCircleOutlined style={{ color: "orange"}}/>&nbsp;{status}</span>
            case "closed":
                return <span style={{color: "green"}}><CheckSquareOutlined style={{ color: "green"}}/>&nbsp;{status}</span>


            default:
                <span style={{color: "orange"}}><PlayCircleOutlined style={{color: "orange"}}/>&nbsp;{status}</span>
        }
    }


    const items: MenuProps['items'] = [
        {
            key: '0',
            icon: <>{setStatusIcon("to do")}</>,

        },
        {
            key: '1',
            icon: <>{setStatusIcon("doing")}</>,

        },
        {
            key: '2',
            icon: <>{setStatusIcon("waiting")}</>,

        },
        {
            key: '3',
            icon: <>{setStatusIcon("closed")}</>,
        }
    ]


    const onClick: MenuProps['onClick'] = ({ key }) => {
        var newstatus = "to do";
        switch (key) {
           case "0" : setStatus("to do"); newstatus = "to do";break;
           case "1" :setStatus("doing"); newstatus = "doing";break;
           case "2" :setStatus("waiting"); newstatus = "waiting"; break;
           case "3" :setStatus("closed"); newstatus = "closed";break;
           
       }

        axios.put(DATA_URL+"todo/"+activityId,
            {status: newstatus}
            ,{ headers: {Authorization: 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjI4YmI4NGYwMmNiNjkyYThlNGMwM2MiLCJlbWFpbCI6Im1lQG1vcm96b3Yuc2kiLCJleHAiOjE3MjE2NzU5MTEyMTcsImlhdCI6MTcyMTY2ODcxMX0.c_nT1G9wYpMm4vd0Ctg01rlVYkLBKr4My_qAZF4JJbo'}}
        )
       
       if(afterSelect){
           console.log("value for afterSelect: ", newstatus)
           afterSelect(newstatus)
           
       }
    };
    
    
    

    return<>
        
        <Dropdown menu={{ items, onClick }}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <span style={{fontSize: "16px"}}>{setStatusIcon(status)} </span>
                    <DownOutlined style={{color: "#999"}} />
                </Space>
            </a>
        </Dropdown>
    </>
}

export default ActivityStatusSelect