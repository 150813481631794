import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider, Drawer,
    Dropdown,
    Form,
    Input,
    MenuProps,
    Row,
    Select,
    Space,
    Spin, Tooltip
} from "antd";
import {useTranslation} from "react-i18next";
import Doc from "../../../types/DocPosition";
import {dateForServer, dateToPoints, mapDocTypeEnumToString, ServerdateToPoints} from "../../../utils/helpers";
import authHeader from "../../../Services/auth-header";
import axios from "axios";
import dayjs from 'dayjs';
import PositionsList from "./positionList";
import invoicePosition from "../../../types/DocPosition";
import {DownOutlined, PrinterOutlined} from "@ant-design/icons";
import ShowDoc from "./ShowDoc";
import EditContractorComp from "../../Counterparts/Components/EditComponent";
import OrganizationSelect from "../../../CommonComponents/OrganizationSelect";
import invoice from "../../../types/invoice";
import OrganisationDTO from "../../../types/OrganisationDTO";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";
import CounterPartSelect from "./CounterpartSelect";
import BankAccountDetailPage from "../../Settings/Components/BankAccountDetails";
import bankAccount from "../../../types/BankAccount";
import BankAccountSelect from "../../../CommonComponents/BankAccountSelect";




interface RacunProps {
    id2? : string|null;
    ContractorId?: string;
    forceReload?: boolean;
    isLoaded: () => void;
}
interface Params {
    id: string;
}

const EditDoc = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    
    //console.log(user)
    
    if(!user){
       // history.push("/login");
    }

    const {id} = useParams<Params>();
    const {t} = useTranslation();

    const [theDoc, setDoc] = useState<invoice>({
        integrationSpecificId:"",
        sentOrReceived: "sent",
        year: 1900,
        documentReference: "",
        customer: {integrationSpecificId: "", name: ""},
        dateIssued: "",
        dateDue: "",
        dateTransaction: "",
        dateRecieved: "",
        dateModified: "",
        currency: "",
        status: "",
        paymentStatus: "",
        invoiceValue: null,
        amount: null,
        paidValue: null,
        items: [],
//        docLanguage:"",
        //docPositions:[]
    })
    const [rerender, setrerender] = useState(true);
    const [connectToRequest, setconnectToRequest] = useState(false);
    const [routListOptions, setroutListOptions] = useState<any[]>([]);
    const [routeContractors, setrouteContractors] = useState<any[]>([]);
    const [langIcon, setlangIcon] = useState("en");
    const [docLang, setdoclang] = useState("English");
    const [ContractorOption,setContractorOption]=useState<{label:string, value: string, key?: string|null, davcnastevilka?: string|null }[]>([])
    const [contractorToEdit, setcontractorToEdit]=useState("");
    const [drawerTitle, setdrawerTitle]=useState("Ustvarite_novo_podjetje");
    const [openContractor, setopenContractor] = useState(false);
    
    
    const[bankAccounts,setBankAccounts]= useState<bankAccount[] | null>([]);








    const [formV] = Form.useForm();
    const [formH] = Form.useForm();


    const userOrganisation = AuthService.getCurrentOrganisation();


    useEffect(()=>{
        subscribe("OrganizationChange", (data: any) => history.push("/docs")  )

        console.log(userOrganisation)

        
        if(id!=="createdoc" && theDoc.integrationSpecificId===""){
            const fetchSentInvoices = async (): Promise<invoice> => {

                const response = await axios.get('https://api.dev.kvikbills.com/integration/invoice/sent/'+userOrganisation.id+"/"+id, {headers: authHeader()});
                console.log("theDoc from API: ",response.data)
                setDoc(response.data);
                return  response.data;
               // return response.data.data.map((invoice: invoice) => ({ ...invoice, sentOrReceived: 'sent' }));
            };
            
            fetchSentInvoices()

        }

        return () => {
            unsubscribe("OrganizationChange", (data: any) => history.push("/docs") );
        }

    })
    
    


    
    







    const requestFilterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  

    const onSelectDDate=(value: any)=>{
        setInvalidDocDate("")

        setDoc(prev => ({
            ...prev,
            dateIssued : dateToPoints(new Date(value))
        }));
    }

    const onSelectPDate=(value: any)=>{
        setInvalidPayDate("")
        setDoc(prev => ({
            ...prev,
            dateDue : dateToPoints(new Date(value))
        }));

    }


    const setNewContractor = (value : any):string =>{
        console.log("setNewContractor: ", value)
        setInvalidCustomer("")
        setopenContractor(false);
        if(value!==""){
            setContractorOption(prev => ([
                    ...prev===null? [] : prev,
                    {
                        value: value.integrationSpecificId,
                        label : value.name,
                        key: value.id,
                        davcnastevilka: value.taxNumber
                    }]
            ));
            if(value.taxNumber===""){
                console.log("no ds")
            }
            else{
                formH.setFieldsValue({
                    contractorSelect: value.integrationSpecificId
                });

                setDoc(prev => ({
                    ...prev,
                    customer : 
                        {
                            integrationSpecificId : value.integrationSpecificId,
                            name : value.name,
                            taxNumber: value.taxNumber
                        }
                }));
            }

        }
        return "";
    }


    const handleListChange=(posiitions? : invoicePosition[]) => {
        setInvalidItems("")
        console.log("Editdocpage handleListChange posiitions:", posiitions)
        console.log("Create Doc handleListChange posiitions: ", posiitions)
        if(posiitions!==undefined){
            setDoc(prev => ({
                ...prev,
                items : posiitions.map((pos)=>(
                    {
                        name : pos.name,
                        integrationSpecificId: pos.integrationSpecificId, 
                        price: pos.price, 
                        vatRate: pos.vatRate, 
                        quantity: pos.quantity}))
            }));
        }
        console.log("theDoc (items):", theDoc)
        setrerender(!rerender);
    }


    const printDoc=()=>{
        const printContent = document.getElementById("htmlContainer");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        // @ts-ignore
        WindowPrt.document.write(printContent? printContent.innerHTML : "no content");
        // @ts-ignore
        console.log(WindowPrt.document.readyState)

        // @ts-ignore
        if(WindowPrt.document.readyState==="complete"){
            // @ts-ignore
            WindowPrt.focus();
            // @ts-ignore
            WindowPrt.print();
            // @ts-ignore
            WindowPrt.document.close();
            // @ts-ignore
            WindowPrt.close();
        }









    }
    
    const SaveDocument=(goto?: string|undefined)=>{
        
        if(!formValidation()){
            return
        }

        const newDoc: any={
            integrationSpecificId: theDoc.integrationSpecificId?
                theDoc.integrationSpecificId : ""//"00000000-0000-0000-0000-000000000000"
            ,
            year: 2024,
            //sentOrReceived: "sent",
            customer: {
                integrationSpecificId: theDoc.customer?.integrationSpecificId?.toString(),
                name : theDoc.customer?.name
            },
            dateIssued : dateForServer(theDoc.dateIssued),
            dateDue : dateForServer(theDoc.dateDue),
            currency: "EUR",
            documentReference: "",
            //dateRecieved: "",
            //dateModified: "",
            dateTransaction: dateForServer(theDoc.dateIssued),
            status: theDoc.status? theDoc.status: "isssued draft",
            paymentStatus: theDoc.paymentStatus ? theDoc.paymentStatus : "draft",
            //invoiceValue: theDoc.invoiceValue,
            paidValue: theDoc.paidValue,
            amount: theDoc.amount,
            items: theDoc.items.map((pos)=>(
                {
                    integrationSpecificId: pos.integrationSpecificId?.toString(), 
                    price: pos.price, 
                    vatRate: pos.vatRate, 
                    quantity: pos.quantity, 
                    integrationSpecific: 
                        {
                            vatPercent: 22
                        }
                }
                )),
            
            
//            docPositions: [],
        }
        var newdocId="";
        
        if(theDoc.integrationSpecificId===""){
            console.log("newDoc: ", newDoc)
            console.log("theDoc: ", theDoc)
            axios.post(AuthService.DATA_URL+'integration/invoice/issued/'+userOrganisation.id,
                newDoc, {headers: authHeader()})
                .then((response) =>{
                    console.log("response after post: ", response)
                        newdocId=response.data.integrationSpecificId;
                    
                    console.log("goto: ", goto)
                    
                    
                    if(goto==="1")
                        history.push("/docs/"+newdocId);
                    }
                );
        }
        else{
            console.log("theDoc.id: ", theDoc.integrationSpecificId);
            console.log("newDoc: ", newDoc)

        }
    }



    



    const items: MenuProps['items'] = [
        { key: 'en', icon: <img src="/img/lang/en.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; English</span> },
        { key: 'si', icon: <img src="/img/lang/si.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Slovenščina</span> },
      //  { key: 'fr', icon: <img src="/img/lang/fr.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Français</span> },
      //  { key: 'de', icon: <img src="/img/lang/de.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Deutsch</span>},
        { key: 'ru', icon: <img src="/img/lang/ru.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Русский</span> },
      //  { key: 'it', icon: <img src="/img/lang/it.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Italiano</span> },
      //  { key: 'uk', icon: <img src="/img/lang/uk.svg"  style={{width:"12px", height: "12px"}}/>, label: <span> &nbsp; Українська</span> },
    ]

    const handleLanguageClick: MenuProps['onClick'] = (e) => {
        switch(e.key) {
            case "en": {
                setdoclang("English");
                setlangIcon("en")
                break;
            }
            case "si": {
                setdoclang("Slovenščina");
                setlangIcon("si")
                break;
            }
            case "fr": {
                setdoclang("Français");
                setlangIcon("fr")
                break;
            }
            case "de": {
                setdoclang("Deutsch");
                setlangIcon("de")
                break;
            }
            case "ru": {
                setdoclang("Русский");
                setlangIcon("ru")
                break;
            }
            case "it": {
                setdoclang("Italiano");
                setlangIcon("it")
                break;
            }
            case "uk": {
                setdoclang("Українська");
                setlangIcon("uk")
                break;
            }


            default: {
                setdoclang("English");
                setlangIcon("en")
                break;
            }
        }
        //setdoclang(e.key)
        setDoc(prev => ({
            ...prev,
            docLanguage : e.key
        }));
    };

    const menuProps = {
        items,
        onClick: handleLanguageClick,
    };



    const [invalidCustomer, setInvalidCustomer] = useState<"" | "success" | "error" | "warning" | "validating" | undefined>("success")
    const [invalidDocDate, setInvalidDocDate] = useState<"" | "success" | "error" | "warning" | "validating" | undefined>("success")
    const [invalidPayDate, setInvalidPayDate] = useState<"" | "success" | "error" | "warning" | "validating" | undefined>("success")
    const [invalidBank, setInvalidBank] = useState<"" | "success" | "error" | "warning" | "validating" | undefined>("success")

    const [helpCustomer, setHelpCustomer] = useState(t("Select_the_customer"))
    const [helpDocDate, setHelpDocDate] = useState(t("Specify_the_date"))
    const [helpPayDate, setHelpPayDate] = useState(t("Until_what_date"))
    const [helpBankAccount, setHelpBankAccount]=useState(t("Account_to_which_the_funds_are_to_be_paid"))

    

    const [invalidItemsStatus, setinvalidItemsStatus] = useState<"" | "success" | "error" | "warning" | "validating" | undefined>("success")
    const [invalidItems, setInvalidItems] = useState(t("at_least_one_position"))
    
    
    //validateStatus="success" invalidPayDate
    //help=
    const formValidation =()=>{
        console.log(theDoc);
        let errors=0;

       
        if(theDoc.customer?.integrationSpecificId==="" || theDoc.customer===null){
            setInvalidCustomer("error")
            errors++;
          
        }
        if(theDoc.dateIssued==="" || theDoc.dateIssued==="01.01.1970"){
            setInvalidDocDate("error")
            errors++;
        }
        if(theDoc.dateDue==="" || theDoc.dateDue==="01.01.1970"){
            setInvalidPayDate("error")
            errors++;
        }
        if(!theDoc.bankAccount){
            setInvalidBank("error")
            errors++;
        }

        if(theDoc.items.length===0){
            setinvalidItemsStatus("error")
//            setInvalidItems("Добавьте хотя бы одну позицию")
            errors++;
        }
        
        
        
        if(errors>0){return false}
        return true
    }

    
    
    const setNewAccount=(acc: bankAccount)=>{

        setInvalidBank("")
        console.log("acc: ", acc)
        
                setDoc(prev => ({
                    ...prev,
                    bankAccount : acc,
                }));
    }
    

    if(id!=="createdoc" && theDoc.integrationSpecificId===""){
        return <Spin/>
    }


    const saveItems: MenuProps['items'] = [
        { key: "1",  label: <span>{t("save_and_stay")}</span> },
        { key: "2",  label: <span>{t("save_and_quit")}</span> },
    ]
    const handleSaveOption: MenuProps['onClick'] = (e) => {
        console.log("statis change: ",e)
        SaveDocument(e.key)

    }





    return <>

        <div style={{maxWidth: "100%", overflowX: "hidden"}}>
            <Row>
                <Col span={14}>
                    {/* 
                    <span style={{color: "#265a96", cursor: "pointer",  marginTop: "-25px"}} onClick={()=>{history.push("/docs")}}>&larr; Обратно к списку</span>
*/}

                    {(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc") ?
                        <div style={{color: "red", marginBottom: "10px"}} >{t("Invoice_is_booked")}. {t("No_changes_aalowed")}</div>
                        : ""
                    }


                    <h1>{theDoc.integrationSpecificId===""?
                        t("Nov_dokument")
                        : t(mapDocTypeEnumToString(10))+" № "+theDoc.documentReference
                    }
                        {/* 
                        <span style={{fontSize: "14px", fontWeight: "normal",  marginLeft: "20px"}}> по организации <OrganizationSelect/></span>
*/}
                    </h1>
                    <Form
                        form={formH}
                        layout="horizontal"
                        labelCol={{ flex: '130px' }}
                        labelAlign="left"
                        labelWrap
                        //wrapperCol={{ flex: 1 }}
                        initialValues={
                            {
                                ["contractorSelect"]: theDoc.customer?.integrationSpecificId,
                                ["datumd"] : theDoc.dateIssued?  dayjs(ServerdateToPoints(theDoc.dateIssued), "DD.MM.YYYY") : "",//theDoc.documentDate,
                                ["datump"]: theDoc.dateDue ? dayjs(ServerdateToPoints(theDoc.dateDue), "DD.MM.YYYY") : "",//theDoc.payementDate
                            }
                        }
                    >



                        <Row>
                            <Col span={22}>
                                <Form.Item label={t("Nasprotna_stranka")} style={{marginBottom: "10px"}} >
                                    <Form.Item name="contractorSelect"
                                               help={helpCustomer}
                                               validateStatus={invalidCustomer}
                                               
                                    >
                                        <CounterPartSelect 
                                            afterCreate={setNewContractor}
                                            isDisabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                                            defaultSelected={theDoc.customer?.integrationSpecificId? theDoc.customer?.integrationSpecificId : undefined}/>
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={22}>
                                <Form.Item label={t("bank_account")} style={{marginBottom: "10px"}} >
                                    <Form.Item name="bankaccountSelect"
                                               help={helpBankAccount}
                                               validateStatus={invalidBank}

                                    >
                                        <BankAccountSelect 
                                            afterCreate={(acc)=>setNewAccount(acc)} 
                                            defaultSelected={undefined} 
                                            required={true}
                                            isDisabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                                        />

                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={10}>
                                <Form.Item label={t("Datum_dokumenta")} name="datumd"
                                           help={helpDocDate}
                                           validateStatus={invalidDocDate}>
                                    <DatePicker style={{width: "120px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                                value={dayjs(ServerdateToPoints(theDoc.dateIssued), "DD.MM.YYYY")}
                                                disabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                                                onChange={(e)=>onSelectDDate(e)}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={11}>

                               
                                    <Form.Item label={t("Datum_plačila")} name="datump"
                                               help={helpPayDate}
                                               validateStatus={invalidPayDate}
                                    style={{float: "right"}}>
                                        <DatePicker style={{width: "140px"}} format={"DD.MM.YYYY"} placeholder={"datum"}
                                            //defaultValue={theDoc.payementDate}
                                                    onChange={(e)=>onSelectPDate(e)}
                                                    disabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                                        />
                                    </Form.Item>

                                 
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={23}>
                                <Divider />
                            </Col>
                        </Row>

                    </Form>

                    <Row gutter={8} id={"PositionsList"}>
                        <div style={{maxHeight: "240px", width: "100%", overflowY: "scroll", marginLeft: "5px"}}>
                            <PositionsList ReqId={ undefined}
                                           positions={ theDoc.items.map((item,index)=>({
                                               id: item.integrationSpecificId,
                                               integrationSpecificId:item.integrationSpecificId,
                                               name: item.name,
                                               quantity: item.quantity,
                                               mass: item.mass,
                                               vatRate : item.vatRate,
                                               price : item.price
                                           }))}
                                           informParent={handleListChange}
                                           isDisabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                            
                            />

                            {invalidItemsStatus==="error" ?
                                <div style={{color: "red", padding: "5px"}}>{invalidItems}</div>
                                : 
                                ""}

                        </div>
                    </Row>

                    <Row>
                        <Col span={23}>
                            <Divider/>



                            <Dropdown 
                                disabled={(!theDoc.status?.includes("draft") && id.toLowerCase()!=="createdoc")}
                                menu={{'items': saveItems, onClick: handleSaveOption }} >
                                <Button type={"primary"} 
                                        
                                        className={"orangeButton"}
                                        onClick={()=> {
                                            SaveDocument("2")
                                        }}
                                        style={{ /*float:"right",marginTop:"25px", marginRight:"50px",*/ }}>
                                    {t("Shraniti")}

                                    <DownOutlined />
                                </Button>
                            </Dropdown>



                            {/* 
                            <Button className="orangeButton" type={"primary"} onClick={SaveDocument}>{t('Shraniti')}</Button>
                            
                            */}
                            {
                                id.toLowerCase()==="createdoc"
                                    ?
                                    <Tooltip title={t("before_print")}>
                                    <Button onClick={printDoc}
                                            disabled={true}
                                            style={{ marginLeft: "30px"}}>
                                        <PrinterOutlined/> {t("Tiskanje")}</Button>
                                    </Tooltip>
                                    :
                                    <Button onClick={printDoc}
                                            style={{ marginLeft: "30px"}}>
                                        <PrinterOutlined/> {t("Tiskanje")}
                                    </Button>
                            }
                            
                            

                            <div style={{display: "inline-block", float: "right"}}>
                                <Form.Item  label={t("Jezik_dokumenta")}>
                                    <Dropdown menu={menuProps}>
                                        <Button>
                                            <Space>
                                                <img src={"/img/lang/"+langIcon+".svg"}  style={{width:"12px", height: "12px"}}/>
                                                {docLang}

                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Form.Item>
                            </div>


                        </Col>
                    </Row>


                </Col>
                <Col span={10}>
                    <div className="shadow"
                         style={{backgroundColor: "#fff", padding: "20px",
                             boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.08)",
                             border: "1px solid #e6e6e6",
                         }}
                    >

                         
                        <ShowDoc
                            ContractorId ={                            
                            theDoc.customer?.integrationSpecificId? theDoc.customer?.integrationSpecificId : undefined
                            }
                            //ContractorId={theDoc.customer? theDoc.customer.taxNumber? theDoc.customer.taxNumber : "" : ""} 
                                 forceReload={rerender} ratio={0.48}
                                 RequestId={ undefined}
                                 documentDate={theDoc.dateIssued? (theDoc.dateIssued.length>10? ServerdateToPoints(theDoc.dateIssued) : theDoc.dateIssued) : ""}
                                 payementDate={theDoc.dateDue ? (theDoc.dateDue.length>10 ? ServerdateToPoints(theDoc.dateDue) : theDoc.dateDue) : ""}
                                 documentNumber={theDoc.documentReference ? theDoc.documentReference : ""}
                                 documentType = { 10}
                                 lang={theDoc.docLanguage? theDoc.docLanguage : "en"}
                                 bankAccount={theDoc.bankAccount}

                                 positions={ theDoc.items}

                        />
                        
                    </div>

                </Col>


            </Row>

            {/* 
            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenContractor(false)} open={openContractor}>
                <CreateContractor afterCreate={setNewContractor}  isClient={true}/>
            </Drawer>
            */}

            <Drawer title={t("Ustvarite_novo_podjetje")} placement="right" onClose={()=>setopenContractor(false)} open={openContractor}>
                <EditContractorComp paramValue={contractorToEdit? contractorToEdit : ""} afterCreate={setNewContractor}/>
            </Drawer>






        </div>

    </>

}

export default EditDoc