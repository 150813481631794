import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";
import type { RadioChangeEvent } from 'antd';


import {Button, Card, Checkbox, Col, Row, Space, Spin, message, Upload, Progress, Radio} from "antd";
import authHeader from "../Services/auth-header";
import {useTranslation} from "react-i18next";
import axios from "axios";

type DocProps = {
    objectId: string | null;
    objectMode: number;
    vrstaDoka? : number
    afterCreate?: (data: any) => string;
};

const UploadDoc = ({ objectId, objectMode, vrstaDoka }: DocProps) => {
    const {t} = useTranslation();


    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        history.push("/login");
    }
    const { Dragger } = Upload;



    const userOrganisation = AuthService.getCurrentOrganisation();
    console.log("userOrganisation: ", userOrganisation)



    const truckId='5d0bf8f2-394b-4063-a022-89272ba44d4a';
    const [vrstaDocumenta, setvrstaDocumenta] = useState(vrstaDoka===undefined? 0 : vrstaDoka);
    const [draggerDisabled, setdraggerDisabled] = useState(vrstaDoka===undefined? true : false);


    const onChangevrstaDocumenta = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setvrstaDocumenta(e.target.value);
        setdraggerDisabled(false);
    };


    //http://localhost:5000/api/Docs/CreateFromUpload?requestId=5d0bf8f2-394b-4063-a022-89272ba44d4a&objectMode=2
   
   ///document-inbox/{organizationId}
    const uploadAction =  AuthService.DATA_URL+'document-inbox/'+userOrganisation.parentId;//+objectId+'&objectMode='+objectMode.toString()+'&DocType='+vrstaDocumenta.toString();

    console.log("uploadAction: ", uploadAction)
    const [progress, setProgress] = useState(0);
    
    
    const uploadImage = async (options: { onSuccess?: any; onError?: any; file: any; onProgress?: any; }) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data", "Authorization": 'Bearer ' + user.token },
            onUploadProgress: (event:any) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        fmData.append("childOrganizationId", userOrganisation.id)
        fmData.append("documentStatus", "draft")
        fmData.append("documentType", "received-invoice")
        fmData.append("kvikDocument", file);
        try {
            const res = await axios.post(
                uploadAction,
                fmData,
                config
            );

            onSuccess((sres: any)=>{
                console.log("sres: ", sres);
                //console
            })
//            onSuccess("Ok");
            console.log("server res: ", res);
            history.push(`/docs/received/${res.data._id}/draft`)
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
        
    }



    const onChange =(info : any) =>{
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log("info: ",info);
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }



    // @ts-ignore
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Dragger
                        disabled={draggerDisabled}
                        onChange={onChange}
                        //action={uploadAction} //+'http://localhost:5000/api/Docs/CreateFromUpload?requestId=5d0bf8f2-394b-4063-a022-89272ba44d4a&objectMode=2'

                        customRequest={uploadImage}
                        headers={authHeader()}
                        style={{minWidth: "300px"}}>
                        <Button className={"orangeButton"} htmlType="submit">
                            + {t("Prenesite_dokument")}
                        </Button>
                        <p className="ant-upload-text" ><br/>{t("Prenesite_datoteko_ali_povlecite")}<br/> {t("in_spusti_na_označeno_območje")}<br/></p>
                        <p className="ant-upload-hint">
                            JPG, JPEG, PNG, PDF 
                        </p>
                    </Dragger>
                </Col>
                {/* 
                <Col>
                    <div className="container" style={{marginLeft: "20px", overflow: "scroll", height: "250px", width: "200px"}}>
                        <Radio.Group onChange={onChangevrstaDocumenta} value={vrstaDocumenta}>
                            {
                                objectMode==2 ?
                                    <Space direction="vertical">
                                        <Radio value={21}>{t("Tehnični_potni_list")}</Radio>
                                        <Radio value={22}>{t("Zavarovanje")}</Radio>
                                        <Radio value={0}>{t("Drug_dokument")}</Radio>
                                    </Space> :
                                    objectMode==3 ?
                                        <Space direction="vertical">
                                            <Radio value={31}>{t("Vozniško_dovoljenje")}</Radio>
                                            <Radio value={32}>{t("Zavarovanje")}</Radio>
                                            <Radio value={0}>{t("Drug_dokument")}</Radio>
                                        </Space> :
                                        objectMode==4 ?
                                            <Space direction="vertical">
                                                <Radio value={10}>{t("Račun")}</Radio>
                                                <Radio value={11}>CMR</Radio>
                                                <Radio value={12}>{t("Pogodba")}</Radio>
                                            </Space> :
                                            objectMode==1 ?
                                                <Space direction="vertical">
                                                    <Radio value={10}>{t("Račun")}</Radio>
                                                    <Radio value={11}>CMR</Radio>
                                                    <Radio value={12}>{t("Pogodba")}</Radio>
                                                </Space> :
                                                null

                            }
                            )
                        </Radio.Group>
                    </div>

                </Col>
                */}
            </Row>
        </div>

    )
};
export default UploadDoc;