import React from 'react';
import { Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import data from '../../../data.json';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CommonListFilter from '../../../types/CommonListFilter';

interface Product {
  key: string;
  name: string;
  unit: string;
  stock: number;
  purchasePrice: number;
}

interface CommonTableProps {
    onRow: TableProps<Product>['onRow'];
    filter: CommonListFilter;
  }

const CommonTable = ({ filter } : CommonTableProps) => {
  const history= useHistory();

  const {t} = useTranslation();


  const columns: ColumnsType<Product> = [
    {
      title: t('Product_name'),
      dataIndex: 'name',
      key: 'name',
      filteredValue: [filter?.searchText || ""],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toString().toLowerCase());
      },
      render: (text, record) => {
        return <>{record.name}</>;
      },
    },
    {
      title: t('Unit'),
      dataIndex: 'unit',
      key: 'unit',
      render: (text, record) => {
        return <div>{text}</div>;
      },
    },
    {
      title: t('CurrentStock'),
      dataIndex: 'stock',
      key: 'stock',
      render: (text, record) => {
        return <div>{record.stock}</div>;
      },
    },
    {
      title: t('Purchase_price'),
      dataIndex: 'purchasePrice',
      key: 'purchasePrice',
      width: 150,
      align: 'right',
      sorter: (a, b) => a.purchasePrice - b.purchasePrice,
      render: (text, record) => (
        <div>
          {record.purchasePrice
            ? record.purchasePrice.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + "€"
            : "-"}
        </div>
      ),
    },
  ];

  return (
    <Table
      locale={{
        triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
        triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
        cancelSort: t('Prekliči_razvrščanje'),
      }}
      columns={columns}
      dataSource={data}
      scroll={{ y: 'calc(57vh)' }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            history.push("/warehouse/" + record.key);
          },
        };
      }}
    />
  );
};

export default CommonTable;
