import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CommonTable from './Components/CommonTable';
import CommonListFilter from '../../types/CommonListFilter';


export const WarehousePage = () => {
    const history= useHistory();

    const {t} = useTranslation();

    const handleRowClick = (record: any) => {
        history.push(`/warehouse/${record.key}`);
    };

    const [warehouseFilter, setWarehouseFilter]=useState<CommonListFilter>({
      searchText: "",
      dateFrom: "",
      dateTo: "",
      selectedDates: "",
  })

  
  
  const setCBFilterFunc=(filter: any)=>{
    setWarehouseFilter(filter);
      //console.log("page comp:", filter)
  }
  
    return (
      <div>
        <h1>
        {t("Warehouse")}
        </h1>
        <CommonTable filter={warehouseFilter} onRow={(record) => ({ onClick: () => handleRowClick(record) })} />
      </div>
    );
  };

export default WarehousePage;