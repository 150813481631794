import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import CounterPartsTable from "./Components/CounterpartList";
import {Button, Col, Divider, Row} from "antd";
import {useTranslation} from "react-i18next";
import CommonListFilter from "../../types/CommonListFilter";

const CounterPartList = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();
    
    if(!user){
        history.push("/login");
    }

    const {t} = useTranslation();

    const [pageFilter, setPageFilter]=useState<CommonListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        selectedDates: "",
    })

    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }



    return <>
        <Row>
            <Col span={14}>
        <h1>
            {t("Stranke")}
            
        </h1>
            </Col>
            <Col span={5} style={{display: "flex", alignItems: "center"}}>
        <Button 
            onClick={()=>{history.push("/counterparts/new")}} 
            style={{float: "right"}}
            type={"primary"} >+ {t("Nov_partner")}</Button>
            </Col>
        </Row>
        <Divider/>
        <CounterPartsTable filter={pageFilter}/>

    </>

}

export default CounterPartList