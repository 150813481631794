import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import Product from "../../../types/Product";
import axios from "axios";
import {DATA_URL} from "../../../Services/auth-service";
import authHeader from "../../../Services/auth-header";
import CommonListFilter from "../../../types/CommonListFilter";
import {ColumnsType} from "antd/es/table";
import {useTranslation} from "react-i18next";
import {Spin, Table, TableProps} from "antd";
import {subscribe, unsubscribe} from "../../../CommonComponents/Notifications/NotificationContext";


interface prodListProps{
    filter : CommonListFilter
}

const ProdTable = ({ filter} : prodListProps) => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();


    if(!user){
        history.push("/login");
    }
    const {t} = useTranslation();


    const [userOrganisation, setuserOrganisation] = useState(AuthService.getCurrentOrganisation().id);
//    console.log("userOrganisation: ", userOrganisation)
    const [products, setProducts] = useState<Product[]|null>(null)
    const [pageloading, setpageloading] = useState<boolean>(false)

    
    
    const changeOrg=(id:string, pageSize: number|undefined, currentPage: number | undefined, searchString : string|undefined)=>{
        setuserOrganisation(id)
        getProducts(id, pageSize, currentPage, searchString)
    }
    
    
    const getProducts = (id: string, pageSize: number|undefined, currentPage: number | undefined, searchString : string|undefined) =>{
        setpageloading(true)
        setProducts([])

        axios.get(DATA_URL + "integration/item/"+id
            +"/?page="+currentPage
            +"&pageSize="+pageSize
            , { headers: authHeader()})
            .then((res)=>{
                console.log("products res.data: ", res.data.data)
                setProducts(res.data.data)
                setTotalResults(res.data.pagination.totalResults)
                setpageloading(false)
            })
            .catch(function (error) {
                setProducts([])
                setpageloading(false)

                if(error.response.status===401){
                    AuthService.logout();
                    history.push("/login");
                    window.location.reload();

                }
                if(error.response.status===404){
                    console.log("notFound")
                }

            })
    }


    const[pageSize, setPageSize] = useState(20)
    const[totalResults, setTotalResults] = useState(20)
    const[currentPage, setCurrentPage] = useState(1)

    useEffect(()=>{
        subscribe("OrganizationChange", (data: any) => changeOrg(data.detail,pageSize, currentPage,""))
        //if(products===null){
            getProducts(userOrganisation, pageSize, currentPage, "")
        //}
        return () => {
            unsubscribe("OrganizationChange", (data: any) => changeOrg(data.detail, pageSize, currentPage, ""));
        }


    }, [pageSize, currentPage])


    const columns: ColumnsType<Product> = [
        {
            title: t('Naziv'),
            dataIndex: 'title',
            filteredValue :[filter? filter.searchText? filter.searchText : "" : ""],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())

                )
            },
            render: (text, record) => {
                return (
                    <>
                        {record.name}
                    </>

                )}
        },
        {
            title: t('Unit'),
            dataIndex: 'unitOfMeasurement',
            render: (text, record) => {
                return (
                    <div>
                        {text}
                    </div>
                )}
        },
        {
            title: t('CurrentStock'),
            dataIndex: 'unit',
            render: (text, record) => {
                return (
                    <div>
                        -
                    </div>
                )}
        },
        {
            title: t('Cost'),
            dataIndex: 'purchasePrice',
            width: 150,
            align: 'right',
            sorter: (a:any, b:any) => a.purchasePrice - b.purchasePrice,
            render:(text, record) => <div>
                {
                    record.purchasePrice?
                        record.purchasePrice?.toFixed(2).toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")+"€"
                        :"-"
                }
            </div>
        },

        {
            title: t('Price'),
            dataIndex: 'price',
            width: 150,
            align: 'right',
            sorter: (a:any, b:any) => a.priceForClient - b.priceForClient,
            render:(text, record) => <div>
                {
                    record.price?
                        record.price?.toFixed(2).toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")+"€"
                        :"-"
                }
            </div>
        },
        {
            title: t('Currency'),
            dataIndex: 'currency',
            onFilter: (value, record) => {
                //console.log(record.requestStatus, " : ",filter.requestStatus," : ", filter.hidefinished)
                return (
                    (record.name?.toString() === value.toString() || value.toString() === "100")
                    
                )},

            ellipsis: {
                showTitle: false,
            },
            render:(text, record) =>

                <span style={{color: "#bbb"}}>
                        {record.currency}
                    </span>

        },

    ]



    const handleTableChange: TableProps['onChange'] = (pagination) => {

        console.log("pagination:", pagination)
        setCurrentPage(pagination.current? pagination.current : 1)
        setPageSize(pagination.pageSize ? pagination.pageSize : 20)

        // `dataSource` is useless since `pageSize` changed
        //if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        //    setData([]);
        // }
    };
    

    return <>

        {/* 
        <Spin spinning={pageloading}>
*/}
            <Table
                locale={{
                    triggerDesc: t('Kliknite_za_razvrščanje_padajoče'),
                    triggerAsc: t('Kliknite_za_razvrščanje_naraščajoče'),
                    cancelSort: t('Prekliči_razvrščanje')
                }}
                columns={columns}
                dataSource={products? products : undefined}
                loading={pageloading}
                scroll={{ y: 'calc(57vh)' }}
                pagination={{showSizeChanger: true, pageSize: pageSize, current: currentPage, total: totalResults }} size="small"
                onChange={handleTableChange}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {history.push("/products/"+record.integrationSpecificId)}, // click row
                    };
                }}
            />

            {/* 

        </Spin>
        */}



    </>

}

export default ProdTable