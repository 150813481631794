import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ItemTable from "./Components/ItemTable";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import StockMovement from "../../types/StockMovement";

const { RangePicker } = DatePicker;

const data: StockMovement[] = [
  {
    key: "1",
    name: "Товар 1",
    date: "2023-10-01",
    stock: 100,
    incoming: 50,
    outgoing: 20,
    finalStock: 130,
  },
];

export const WarehouseItemDetails = () => {
  const { id } = useParams<{ id: string }>();

  const item = data.find((movement) => movement.key === id);

  const [dateRange, setDateRange] = useState<
    [Dayjs | null, Dayjs | null] | null
  >(null);
  const [filteredData, setFilteredData] = useState<StockMovement[]>(data);

  const onDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
  };

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;
      const filtered = data.filter((movement) => {
        const movementDate = dayjs(movement.date);
        return movementDate.isAfter(start) && movementDate.isBefore(end);
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [dateRange]);

  return (
    <div>
      <h1>
        {item ? item.name : "Товар не найден"} (движение товара по складу)
      </h1>
        <RangePicker
          value={dateRange}
          onChange={onDateChange}
          format="YYYY-MM-DD"
        />
      <div style={{ marginTop: '20px' }}>
      <ItemTable data={filteredData} />
      </div>
    </div>
  );
};

export default WarehouseItemDetails;
