import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import Activity from "../../../types/Activity";
import {Avatar, Badge, Button, Dropdown, MenuProps, Popover, Select, Space, Tooltip} from "antd";
import {PlayCircleOutlined, WarningOutlined, ClockCircleOutlined, DownOutlined, CheckSquareOutlined} from "@ant-design/icons";
import AssignedUserSelect from "./AssignedUserSelect";
import ActivityCommentsComponent from "./ActivityCommentsComponent";
import ActivityStatusSelect from "./StatusSelect";


interface CompanyEventAcitivityProps{
    organisationId?: string,
    activity? : Activity | null,
    asignee? : string | null
}

const CompanyEventAcitivityComponent = ({organisationId, activity, asignee} : CompanyEventAcitivityProps) => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    
    
    
    const[_activity, set_activity] = useState<Activity|null>(activity? activity : null)
    
    //console.log("activity: ", activity)

    if (!user) {
        history.push("/login");
    }
    const {t} = useTranslation();

    
    const handleAssignedUserselect=()=>{
    }
    
    
    const handleCommentsChange=()=>{
    }

    const handleStatusChange=(status : string)=>{
        console.log("_activity: ", _activity)
        console.log("status: ", status)
        if(_activity){

            console.log("_activity: ", _activity)
            console.log("status: ", status)
            set_activity(prev=>
                ({
                    ...prev, 
                    status:status
            }))
        }
    }
    
    return <>

        {_activity?.status ==="closed" ?
            
            <div style={{width: "100%", color: "green", textAlign: "center", fontSize: "16px"}} >
                <ActivityStatusSelect 
                    defaultStatus={_activity?.status? _activity?.status : undefined} activityId={_activity?._id}
                    afterSelect={(status)=>handleStatusChange(status)}
                
                />

                {/*<CheckSquareOutlined  /> {t("Done")} */}
            </div> 
        
            :
            <div>
                {activity?.assingedTo===null?
                    <>
                        <div style={{marginBottom: "10px"}}>
                        <span style={{borderBottom: "1px dashed",  cursor: "pointer"}}>
                             Начать
                        </span>
                        </div>
                        <AssignedUserSelect afterSelect={handleAssignedUserselect} />
                    </>

                    :

                    <div>
                        {activity?.assingedTo!==null ?
                            <span>
                           
                        <div style={{fontSize:"14px", /*color: activity?.assingedTo?  activity?.assingedTo[0].color : ""*/}}>
                        
                            {/* 
                            <Badge color={activity?.assingedTo? activity?.assingedTo[0].color : ""} />
                            */}
                            
                            <Avatar size={18} style={{backgroundColor: activity?.assingedTo ? activity?.assingedTo[0].color : ""}} >
                                {" "+(activity?.assingedTo? activity?.assingedTo[0].firstName?.substring(0,1).toUpperCase(): "") 
                                    +(activity?.assingedTo? activity?.assingedTo[0].lastName?.substring(0,1).toUpperCase() : "")}
                            </Avatar>
                            
                            
                            
                            <span>{" "+(activity?.assingedTo? activity?.assingedTo[0].firstName: "") +" "+(activity?.assingedTo? activity?.assingedTo[0].lastName : "")}</span><br/>
                        </div>
                        </span>

                            :
                            ""
                        }

                        <ActivityStatusSelect 
                            defaultStatus={_activity?.status? _activity?.status : undefined} 
                            activityId={_activity?._id}
                            afterSelect={(status)=>handleStatusChange(status)}
                        />

                        <br/>
                        <ActivityCommentsComponent activity={activity} comments={activity?.comments? activity?.comments: []} afterSelect={handleCommentsChange}/>

                    </div>

                }
            </div>
        }
        
        
        
     
    </>
}

export default CompanyEventAcitivityComponent