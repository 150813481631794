import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../Services/auth-service";

const LogOut = () => {
    const history= useHistory();
    AuthService.logout();
    history.push("/login");
    window.location.reload();
    
    return <></>
    
}

export default LogOut;