import {useHistory, useParams} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import invoice from "../../../types/invoice";
import axios from "axios";
import authHeader from "../../../Services/auth-header";
import {DATA_URL} from "../../../Services/auth-service";
import {Card, Col, Descriptions, DescriptionsProps, Row, Spin} from "antd";
import {mapDocTypeEnumToString, ServerdateToPoints, ToMoney} from "../../../utils/helpers";

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {Document, Page, pdfjs} from "react-pdf";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";




function blobToURL(blob: any) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}


//pdfjs.GlobalWorkerOptions.workerSrc ="/../../public/pdf/pdf.worker.js"
//https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.worker.mjs`;

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



interface Params {
    id: string;
    status: string;
}
const DisplayReceived = () => {
    const history = useHistory();
    const user = AuthService.getCurrentUser();

    

    if (!user) {
        // history.push("/login");
    }

    const {id} = useParams<Params>();
    const {status} = useParams<Params>();
    const {t} = useTranslation();


    const userOrganisation = AuthService.getCurrentOrganisation();


    const [invoice, setInvoice] = useState<invoice| null>( null)
    const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);


    const [pdfUrl, setpdfUrl] = useState<any | null>(null);
    const [fileUrl, setfileUrl] = useState<any | null>(null);


    const [pageLoading, setPageLoading] = useState(false)


    useEffect(()=>{
        if(invoice===null){
            setPageLoading(true);
            
            
            if(status==="draft"){
                const fetchSentInvoice = async (): Promise<invoice> => {

                    const response = await axios.get(AuthService.DATA_URL+'document-inbox/id/'+id, {headers: authHeader()});
                    console.log("response.data:", response.data)

                    setInvoice({
                        year: 2024,
                        dateTransaction: "",
                        dateModified: "",
                        dateRecieved: "",
                        currency: response.data.prediction.invoiceTotal.value.currencyCode,
                        paymentStatus: "",
                        invoiceValue: 0,
                        items: [],
                        paidValue: 0,
                        integrationSpecificId: response.data._id,
                        documentReference: response.data.prediction.invoiceId.content,
                        amount: response.data.prediction.invoiceTotal.value.amount,
                        dateIssued: response.data.prediction.invoiceDate.value,
                        dateDue: response.data.prediction.dueDate.value,
                        status: response.data.integrationSendStatus,
                        customer: {
                            name : response.data.prediction.vendorName.content,
                            taxNumber: response.data.prediction.vendorTaxId.content,
                            address:{street: response.data.prediction.vendorAddress.content},

                        },
                        sentOrReceived: 'received'}
                    )
                    
                    console.log("url: ", "https://storage.dev.kvikbills.com/"+encodeURIComponent( response.data.fullFilename));
                    //setpdfUrl("https://storage.dev.kvikbills.com/"+encodeURIComponent( response.data.fullFilename))


                    setfileUrl( "https://storage.dev.kvikbills.com/"+encodeURIComponent( response.data.fullFilename));

                        /*
                    const arrayBuffer = await 
                        fetch("https://storage.dev.kvikbills.com/"+encodeURIComponent( response.data.fullFilename),
                            {
                                mode: 'no-cors',
                                //method: "get",
                            }
                        );

                    console.log("arrayBuffer: ", arrayBuffer);
                    const blob = await arrayBuffer.blob();
                    console.log(blob)
                    setpdfUrl( await blobToURL(blob));
                    
                         */
                    
                    
                    
                    
                    setPageLoading(false)

                    return response.data
                }
                fetchSentInvoice()
            
                
                if(pdfBlob===null && status!=="draft"){
                    /*
                    const attachresponse = await axios.get(DATA_URL+'integration/invoice-attachments/'+userOrganisation.id+"/"+id, {headers: authHeader()});
                    if (attachresponse.data && attachresponse.data.length > 0) {
                        const byteCharacters = atob(attachresponse.data[0].attachment);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        setPdfBlob(new Blob([byteArray], {type: attachresponse.data[0].mimeType}));
                    }
                    */
                }
                
                 
            };


        }
        
        
    })


    const items: DescriptionsProps['items'] = [
        {
            key: '0',
            label: 'Counterpart',
            span: 4,
            children: invoice?.customer?.name,
        },
        {
            key: '1',
            label: 'Issue date',
            children: ServerdateToPoints(invoice?.dateIssued),
        },
        {
            key: '2',
            label: 'Due date',
            span: 2,
            children: ServerdateToPoints(invoice?.dateDue),
        },

        {
            key: '3',
            label: 'Invoice amount',
            span:4,
            children:  invoice?.invoiceValue ? ToMoney(invoice?.amount? invoice?.amount : 0) : "-",
        },
        {
            key: '4',
            label: 'Payed amount',
            children: invoice?.paidValue ? ToMoney(invoice?.paidValue) : "-",
        },
        {
            key: '5',
            label: 'Is paid',
            children: invoice?.dateTransaction? ServerdateToPoints(invoice?.dateTransaction) : "-",
        },
        {
            key: '6',
            label: 'Status',
            children: invoice?.paymentStatus 
        },
    ];


    // @ts-ignore
    return <Spin spinning={pageLoading}>
        {/* 
        <span style={{color: "#265a96", cursor: "pointer", position: "absolute", marginTop: "-25px"}} onClick={()=>{history.push("/docs")}}>&larr; Обратно к списку</span>
        */}
        <h1>{invoice?.integrationSpecificId===""?
            t("Nov_dokument")
            : t(mapDocTypeEnumToString(10))+" № "+invoice?.documentReference
        }
        </h1>
    <Row>
        <Col span={11}>
            <Card>
            <Descriptions 
                layout="horizontal" 
                items={items} 
                labelStyle={{width: "120px"}}
                contentStyle={{minWidth: "120px", filter: "blur(2px)"}} column={2} />
                <div className={"blurhint"}>На обработке</div>
            </Card>
            

        </Col>
        
        <Col span={10} offset={1} >

            {fileUrl && (
                <Document file={fileUrl}>
                    <Page pageNumber={1}
                          width={document.getElementsByClassName('react-pdf__Page')[0]?.clientWidth*0.98 ?? 150}
                          //height={document.getElementsByClassName('PdfDiv')[0]?.clientHeight*0.7 ?? 150}
                    
                    />
                </Document>
            )}

            {pdfUrl?

                <div style={{overflowY: "scroll", height: "75vh", width: "90%"}}>
                    <div>
                        {pdfUrl && !pageLoading &&(
                            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                                {/*
                                <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
                                 <Viewer fileUrl={pdfUrl? pdfUrl : ""} />
                                */}


                                <Viewer fileUrl={URL.createObjectURL(pdfUrl? pdfUrl : "")} />



                            </Worker>
                        )}
                    </div>
                </div>
            
                :<></>
            }
        </Col>
    </Row>
    
        
        
    
    </Spin>

}


export default DisplayReceived