import axios from "axios";
import authHeader from "./auth-header";
import {DATA_URL} from "./auth-service";
import * as AuthService from "./auth-service";
import bankAccount from "../types/BankAccount";

export const PUTBankAccount =(bankAccounts: bankAccount[]) =>{

    const userOrganisation = AuthService.getCurrentOrganisation();
    
    const organisationToSend={
        taxNumber: userOrganisation.taxNumber,
        isVatRegistered: userOrganisation.isVatRegistered,
        registrationNumber: userOrganisation.registrationNumber,
        address: {
            street: userOrganisation.street,
            city: userOrganisation.city,
            zipCode: userOrganisation.zipCode,
            countryCode: userOrganisation.countryCode
        },
        bankAccounts: bankAccounts
    }

    userOrganisation.bankAccounts=bankAccounts;
    console.log("userOrganisation in PUTBankAccount: ", userOrganisation)

    
    axios.put(AuthService.DATA_URL+'organization/'+userOrganisation.id,
        organisationToSend,
        {headers: authHeader()})
        .then((putres) =>{
            localStorage.setItem("current_organization", JSON.stringify(userOrganisation));



            //console.log("put result: ", putres.data)
        })
}