import React, { useState} from 'react';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Form,
    Input,
    Card,
    Row,
    Col,
    Spin,
    message,
    ConfigProvider
} from 'antd';
import {getUsersOrganisations, loadUsersOrganisations, login, logout} from "../Services/auth-service";
import {useHistory} from "react-router-dom";
import i18n from "i18next";
import * as AuthService from "../Services/auth-service";
import {useTranslation} from "react-i18next";
import axios from "axios/index";
import authHeader from "../Services/auth-header";
import LangSelect from "../CommonComponents/LangSelect";
import "../Services/multiLang";



const LoginPage = () => {



    const tt: any = localStorage.getItem("userlanguage")===null ? "" : localStorage.getItem("userlanguage");
    const {t} = useTranslation('translation', { lng: tt });

    const locationString = window.location;
    
    const[username, setusername] = useState<string>("");
    const[password, setpassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false)


    const history= useHistory();

    localStorage.removeItem("current_organization");



    const user = AuthService.getCurrentUser();
    if(user){
        //logout();
        //window.location.reload();
        //console.log("login user", user)
        history.push("/dashboard");
    }

    console.log("AuthService.getCurrentOrganisation(): ",AuthService.getUsersOrganisations())
    console.log("AuthService.getCurrentOrganisation(): ",AuthService.getCurrentOrganisation())
    
    console.log("local storage - organizations", localStorage.getItem("organizations"))
    console.log("local storage - current_organization", localStorage.getItem("current_organization"))

    
    
    

    const onFinish=()=>{
        setLoading(true)
        console.log("On finish login")
        login(username, password).then(
            () => {
                loadUsersOrganisations();
                console.log("localStorage: ", localStorage);
                history.push("/dashboard");
                window.location.reload();
            },
            (error) => {
                setLoading(false)
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                message.error(t("Error"));
                console.log(resMessage);
                //history.push("/");

            })
    }

    return (
        

        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#443fde',
                    colorLink: '#443fde',
                    colorWarning: "#fc7207",
                },
            }}>
            <Spin spinning={loading}>
               
                <Row style={{height: "80vh", justifyContent: "center"}}>
                    <Col style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        width: "400px"

                    }}>
                        <div>

                            <img style={{display: "block", margin:"0 auto", width: "100px", cursor: "pointer"}}
                                 alt="KvikBills"
                                 src={"https://assets-global.website-files.com/65211fe49faa1d76d434dd27/65212e53ab98d7fee8ecf9c9_KB%20logo%20new.svg"}
                                 onClick={()=> {
                                     history.push("/");
                                     window.location.reload();
                                 }}
                            />

                            <Card style={{width: "100%", display: "block", marginTop: "20px", textAlign: "left"}}
                                  title={t("Log_in")}

                                  extra={<LangSelect/>
                                  }
                            >



                                <Form
                                    name="normal_login"
                                    className="login-form"


                                    initialValues={{ remember: true,
                                        username:username,
                                        password : password

                                    }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: t('Please_input_your_Username') }]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Login")}
                                               onChange={(e)=>{setusername(e.target.value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: t('Please_input_your_Password') }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder={t("Password")}
                                            onChange={(e)=>{setpassword(e.target.value)}}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item name="remember" valuePropName="checked" noStyle>
                                            <Checkbox>{t('Remember_me')}</Checkbox>
                                        </Form.Item>

                                        <a className="login-form-forgot" href="" style={{float: "right"}}>
                                            {t("Forgot_password")}
                                        </a>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button" style={{width: "100%"}}>
                                            {t("Log_in")}
                                        </Button>

                                    </Form.Item>
                                </Form>
                                
                            </Card>
                        </div>

                    </Col>

                </Row>
            </Spin>
        </ConfigProvider>



    )
}

export default LoginPage;