import React, {useState} from "react";
import {Select} from "antd";
import Activity from "../../../types/Activity";
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";


interface AssegnedUserSelectProps{
    afterSelect? : (value: any)=>void
}

const AssignedUserSelect=({afterSelect}: AssegnedUserSelectProps)=>{
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
        // history.push("/login");
    }
    
    return<>
        <Select
            style={{width: "120px"}}
            placeholder={"Выбрать"}
            options={[{label: "Tim Ism", value: "timsim"},{label: "Peter Novak", value: "peternovak"}]}
        />
    </>
}

export default AssignedUserSelect