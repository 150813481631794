import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../../Services/auth-service";
import Product from "../../../types/Product";

import ProductSelect from "./ProductSelect";

import {Form, Input, Row, Col, Select, InputNumber, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;
import {vatRateToNumber} from "../../../utils/helpers";
interface DocPositionProps {
    keyStr?: number;
    integrationSpecificId? : string;
    name? : string;
    quantity? : number;
    price? : number;
    vatRate? : string;
    forceReload?: boolean;
    isLoaded?: () => void;
    deleteFunc?: (key: number) => void;
    addFunc?: (values: any) => void;
    isDisabled? : boolean;

}

const DocPositionForm = ({keyStr,integrationSpecificId, forceReload, name, quantity, price, vatRate, isLoaded, deleteFunc, addFunc, isDisabled}: DocPositionProps) => {

    /*
    console.log("Position Form - incoming keyStr: ", keyStr)
    console.log("Position Form - incoming keyStr: ", integrationSpecificId)
    
     */
    const history = useHistory();
    const user = AuthService.getCurrentUser();
    if (!user) {
       // history.push("/login");
    }


    const [formPos] = Form.useForm();

    const [_productId, set_productId] = useState("0");
    const [_naziv, set_naziv] = useState();
    const [_kolicina, set_kolicina] = useState();
    const [_cena, set_cena] = useState();
    const [_davek, set_davek] = useState();




    useEffect(()=>{
        formPos.setFieldsValue(
            {
                ["naziv"] : name ? name : _naziv? _naziv : "",
                ["kolicina"] : quantity ? quantity : _kolicina? _kolicina : 0,
                ["davek"] : vatRate ? vatRate : _davek? _davek : "standard",
                ["cena"] : price ? price : _cena? _cena : 0
            }
        );
    },[name, quantity, vatRate, price])


    const deletePos=(key:number)=>{
        if(deleteFunc){
            deleteFunc(key);
        }
    }
    //console.log(keyStr)

    const SavePosition = (prodId: any)=>{
        
        console.log("SavePosition received value:", prodId)
        console.log(_productId)

        console.log("PositionForm _productId to send: ", _productId)
        console.log("PositionForm prodId to send: ", prodId)
        console.log("PositionForm keyStr to send: ", keyStr)


 

       

        const sendValues = {
            id : keyStr,
            name : formPos.getFieldValue(["naziv"]),
            price: formPos.getFieldValue(["cena"]),
            vatRate: formPos.getFieldValue(["davek"]),
            quantity: formPos.getFieldValue(["kolicina"]),
            integrationSpecificId: prodId,
        }

        console.log("PositionForm values to send: ", sendValues)
        set_naziv(formPos.getFieldValue(["naziv"]));
        set_kolicina(formPos.getFieldValue(["kolicina"]))
        set_cena(formPos.getFieldValue(["cena"]));
        set_davek(formPos.getFieldValue(["davek"]))

        if(addFunc){
            addFunc(sendValues);
        }
    }

    if(!keyStr)return(
        <Spin> </Spin>
    )

    const setNewProduct=(prod?: Product)=>{
        console.log("Product received in PositionForm", prod)
        set_productId(prod?.integrationSpecificId ? prod.integrationSpecificId : "111")
        console.log(prod?.integrationSpecificId ? prod.integrationSpecificId : "111")
        formPos.setFieldsValue(
            {
                ["naziv"] : prod?.name,
                ["kolicina"] : 1,
                ["davek"] : prod?.vatRate,
                ["cena"] : prod?.price
            }
        );
        SavePosition(prod?.integrationSpecificId);
    }

    return(
        <div style={{width: "100%"}}>


            <Form
                form={formPos}
                name={keyStr.toString()}
                layout="vertical"
                //labelCol={{ flex: '100px' }}
                labelAlign="right"
                labelWrap
                //wrapperCol={{ flex: 1 }}
                //onFinish={SavePosition}
                onChange={()=>SavePosition(_productId)}
                disabled={isDisabled}
                initialValues={
                    {
                        ["vrsta"]:0
                    }
                }
            >

                <Row  gutter={8}>
                    <Col span={11}>
                        <Form.Item name="naziv" label="">
                            <ProductSelect afterCreate={setNewProduct} defaultSelected={integrationSpecificId? integrationSpecificId : "0"}  />
                            {/* 

                            <Input placeholder={"Naziv storitva/blaga"} 
                            />*/}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="kolicina" label="">
                            <InputNumber 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="davek" label="">
                            <Select
                                options={
                                    [
                                        /*
                                        { label: "22%", value: 2200 },
                                        { label: "9.5%", value: 950 },
                                        { label: "8%", value: 800 },
                                        { label: "5%", value: 500 },
                                        { label: "Exempted 0%", value: 0 },
                                        { label: "Non-taxable 0%", value: 1 },
                                        
                                         */


                                        { label: "22%", value: "standard" },
                                        { label: "9.5%", value: "reduced" },
                                        { label: "8%", value: "flat" },
                                        { label: "5%", value: "lower" },
                                        { label: "Exempted 0%", value: "exempted" },
                                        { label: "Non-taxable 0%", value: "non-taxable" },
                                        
                                    ]}
                                onChange={()=>SavePosition(_productId)}
                                //onChange={(e) => ShowContractorDoc(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="cena" label="">
                            <InputNumber
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} style={{paddingTop: "4px"}}>

                        {isDisabled ?
                        ""
                        :
                            <a onClick={()=>deletePos(keyStr ? keyStr : 0)} ><DeleteOutlined /></a>
                        }
                        

                    </Col>


                </Row>
                {_naziv ? "" :
                    "" //<Button htmlType="submit">Shraniti</Button>
                }
            </Form>

        </div>
    )}
export default DocPositionForm;