import React, {useContext, useEffect, useState} from 'react';

import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, MenuProps, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import * as AuthService from "../Services/auth-service";
import authHeader from "../Services/auth-header";
import {DATA_URL} from "../Services/auth-service";
import axios from "axios";
import Organization from "../types/Organization";
import {NotificationContext, publish} from "./Notifications/NotificationContext";
import {useHistory} from "react-router-dom";

const OrganizationSelect = () => {
    const {t} = useTranslation();
    const [currentOrg, setcurrentOrg] = useState("");
    const {currentOrganizationId, setcurrentOrganizationId} =  useContext(NotificationContext);


    const [render, setrender] = useState(true)

    const [organisations, setOrganisations] = useState<Organization[]>([])

    const history= useHistory();


    //console.log("Users Organisations: ", AuthService.getUsersOrganisations() )

    let orgs= AuthService.getUsersOrganisations();
    useEffect(()=>{
        if(render){
            if(organisations===null){

                if(orgs===null){


                    axios.get(DATA_URL + "organization/", { headers: authHeader()
                    })
                        .then((org_response) => {
                            console.log("org_response.data: ", org_response.data)
                            localStorage.setItem("organizations", JSON.stringify(org_response.data));
                            orgs= AuthService.getUsersOrganisations();
                            setOrganisations(org_response.data);
                            orgs = org_response.data;
                            //console.log("organizations: ", orgs);
                            let curr_org = AuthService.getCurrentOrganisation()
                            if(curr_org){
                                setcurrentOrg(curr_org.label);
                            }
                            else{
                                localStorage.setItem("organizations", JSON.stringify(org_response.data[0]));
                                setcurrentOrg(org_response.data[0].legalName)
                            }
                        })
                }
                else{
                    setOrganisations(orgs);
                    let curr_org = AuthService.getCurrentOrganisation()
                    if(curr_org){
                        setcurrentOrg(curr_org.legalName);
                    }
                    else{
                        curr_org=orgs[0];
                        localStorage.setItem("current_organization", JSON.stringify(curr_org));
                        setcurrentOrg(curr_org.legalName);
                    }
                }
            }
            else {
                let curr_org = AuthService.getCurrentOrganisation()
                if (curr_org) {
                    setcurrentOrg(curr_org.legalName);
                    setcurrentOrganizationId(curr_org.id)

                } else {
                    curr_org=AuthService.getUsersOrganisations()[0];
                    console.log("curr_org: ", curr_org)
                    if(curr_org){
                        localStorage.setItem("current_organization", JSON.stringify(curr_org));
                        setcurrentOrg(curr_org.legalName);
                        setcurrentOrganizationId(curr_org.id)
                    }
                    else{
                        AuthService.logout();
                        //history.push("/login");
                    }
                }
            }
            
 
        }
        setrender(false)




        
        
    }, [])
    const items: MenuProps['items'] = organisations? organisations.map((org)=>
        ({key: org.id ? org.id : "0", label: org.legalName ? org.legalName : "-"})) : [{key: "0", label: "0"}] 

    const handleOrganisationClick: MenuProps['onClick'] = (e) => {

        // @ts-ignore   current_organization
        var curr = organisations.find(i=>i.id===e.key)
        //console.log("organisations: ", organisations);
        console.log("curr: ", curr);
        localStorage.setItem("current_organization", JSON.stringify(curr));
        // @ts-ignore
        setcurrentOrg(curr.legalName)
        setcurrentOrganizationId(curr? curr.id? curr.id : "" : "")



        publish("OrganizationChange", curr? curr.id? curr.id : "" : "")
        

    };

    const menuProps = {
        items,
        onClick: handleOrganisationClick,
    };
    
    
    


    return(
        <Dropdown menu={menuProps}>
            <Button>
                <Space>
                    {currentOrg}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )



}

export default OrganizationSelect;