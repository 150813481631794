import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import * as AuthService from "../../Services/auth-service";
import ProdTable from "./Components/ProductTable";
import {Button, Col, Divider, Row, TableProps} from "antd";
import {useTranslation} from "react-i18next";
import CommonListFilter from "../../types/CommonListFilter";
import ProductListFilter from "./Components/ProductListFilter";

const ProductsList = () => {
    const history= useHistory();
    const user = AuthService.getCurrentUser();


    if(!user){
        history.push("/login");
    }

    const {t} = useTranslation();

    const [pageFilter, setPageFilter]=useState<CommonListFilter>({
        searchText: "",
        dateFrom: "",
        dateTo: "",
        selectedDates: "",
    })



    
    
    const setCBFilterFunc=(filter: any)=>{
        setPageFilter(filter);
        //console.log("page comp:", filter)
    }







    return <>
       <Row>
           <Col span={14}>
               <h1>
                   {t("Izdelki")}
               </h1>
           </Col>
           <Col span={5} style={{display: "flex", alignItems: "center"}}>
               <Button
                   style={{/*float: "right"*/}}
                   onClick={()=>history.push("products/0")}
                   type={"primary"} >+ {t("add_new_product")}</Button>
           </Col>
       </Row>
        <Row>
            <Col span={24}>

                {/* 
                <ProductListFilter setFilterFunc={setCBFilterFunc} />
*/}
                
            </Col>
        </Row>
        <Divider/>
        <ProdTable filter={pageFilter}/>
    
    </>

}

export default ProductsList