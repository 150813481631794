import i18n from "i18next";
import {initReactI18next} from "react-i18next";


i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                Sart_quantity: "Start quantity",
                On_processing: "In Processing",
                May_still_make_changes: "You can still make changes",
                Status: "Status",
                Sent_invoice: "Sent invoice",
                Received_invoice: "Received invoice",
                Error: "Error",
                Osnovni_podatki:'General info',
                Zgodovina_opravljenih_letov : 'History of orders',
                Urejanje_podatkov_o_partnerju : 'Edit contractor\'s data',
                Podatki_dokumenta: "Document data",
                Invoice_is_booked: "The invoice is on the books",
                No_changes_aalowed: "It cannot be changed",
                Add_new_bank_account: "Add new bank account",
                Account_to_which_the_funds_are_to_be_paid: "Account to which the funds are to be paid",
                bank_account: "Bank account",
                before_print: "Please, save the the document before printing",
                IbanError: "Invalid IBAN format",
                BicError: "Invalid BIC format",
                Prenesite_dokument: 'Upload document',
                Prenesite_datoteko_ali_povlecite : 'Upload file or drag',
                in_spusti_na_označeno_območje : 'and drop it in this area',
                No_Bank_froinvoice: "To create an issued invoice, add your bank details or click \"Don't save\"",
                Postcode: "Postal code",
                Search_for_company: "Search for company",
                Done: "Done!",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                No_bank_accs: "You haven't added any accounts yet",
                Bank_accounts: "Bank accounts",
                Edit_bank_account: "Edit your bank account",
                privacy_confirm_help: "Your consent to the terms of processing of personal data is required",
                rules_confirm_help: "Your consent to the terms of use is required",
                Select_the_customer: "Select the customer to whom you want to issue the invoice",
                Specify_the_date:"The date of invoice",
                Until_what_date: "Until what date",
                at_least_one_position: "Add at least one item",
                Davek_za_DDV: "VAT",
                isssued_draft:"draft",
                issued:"issued",
                received_confirmed:"received, confirmed",
                received_rejected:"received, rejected",
                received_draft:"draft",
                paid:"paid",
                partially_paid_overdue:"partially paid, overdue",
                partially_paid:"partially paid",
                unpaid_overdue:"unpaid, overdue",
                unpaid:"unpaid",
                draft:"draft",
                advance_payment:"advance payment",
                Delete_warning: "Is this really what you want?",
                Delete_text: "An item can be deleted if there are no invoices showing the item. Delete an item?",
                Yes: "Yes",
                No: "No",
                goods: "Goods",
                material: "Material",
                semifinishedProduct: "Semifinished product",
                product: "Product",
                services:  "Services",
                advancePayment: "Advance payment",
                advancePaymentServices: "Advance payment for services",
                Welcomed_by: "Welcome to ",
                Good_afternoon:"Good afternoon.",
                What_do_you_want_to_do:"What do you want to do?",
                Issue_an_invoice_to_a_customer:"Issue an invoice to a customer",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"You can also create a new invoice from the 'Documents' section",
                Upload_incoming_invoice:"Upload incoming invoice",
                Upload_invoice_scan:"Upload an incoming invoice in pdf, jpg or png format",
                Upload_a_statement:"Upload a statement",
                Upload_pdf_or_csv:"Upload a csv file or scan in pdf, jpg or png format.",
                Send_us_documents:"Send us documents",
                Contracts_annexes_and_other_documents:"Contracts, annexes, and other documents",
                Tasks:"Tasks",
                No_new_tasks:"No new tasks",
                Interface_language:"Interface language",
                Events_in_June:"Events in June",
                Salary_payment:"Salary payment",
                Small_checklist:"Small checklist",
                Has_anyone_had_a_salary_increase:"Has anyone had a salary increase?",
                Is_the_account_balance_sufficient: "Is the account balance sufficient for disbursements?",
                Statehood_Day:"Statehood Day",
                Its_a_day_off_in_Slovenia:"It's a holiday in Slovenia.",
                Bank_statement:"Bank statement",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Generate a bank statement for June and upload it for us",
                Street_and_house_number: "Street and house number",
                City: "City",
                ZipCode: "Zip Code",
                More: "More",
                Podatki_o_partnerju: "Partner details",
                Stranka: "Client",
                Izvajalec: "Contractor",
                Stranka_ali_izvajalec: "Client or contractor",
                Kontaktna_oseba: "Contact person",
                Telefon: "Phone",
                Faks: "Fax",
                Delete: "Delete",
                Product: "Product",
                Service: "Service",
                Ne_shranite_sprememb: "Don't save",
                Nov_izdani_račun: "New issued invoice",
                Nov_prejeti_račun: "New received invoice",
                from: "from",
                Predogled: "Preview",
                Vsa: "All",
                Nova: "New",
                Zaključena : "Completed ",
                Od_datum: "From date",
                Do_datum: "To date",
                Show_archived: "Show archived",
                Išči: "Search",
                Sent_invoices: "Issued invoices",
                Received_invoices: "Received invoices",
                Vrsta_ddokumenta: "Document type",
                Amount: "Amount",
                Nov_partner: "New counterpart",
                title:"Title",
                Unit:"Unit",
                CurrentStock:"Current Stock",
                Cost:"Cost",
                Price:"Price",
                Currency:"Currency",
                Kliknite_za_razvrščanje_padajoče:"Click to sort descending",
                Kliknite_za_razvrščanje_naraščajoče:"Click to sort ascending",
                Prekliči_razvrščanje:"Cancel sort",
                Več: "More",
                Naziv : 'Title',
                Davčna_Številka : 'Tax Id',
                Matična_Številka : 'Company Id',
                Naslov : 'Address',
                Domov : 'Home',
                Dokumenti : 'Documents',
                Stranke :' Partners',
                Nastavitve :'Settings',
                Izdelki: "Products",
                Odjava : 'Exit',
                E_pošta : 'Email',
                Staro_geslo : 'Old password',
                Novo_geslo: 'New password',
                Ime: 'Name',
                Priimek : 'Surname',
                Nastavitve_uporabnika : 'User\'s settings',
                Nastavitve_podjetja : 'Company settings',
                new_product: "New product/service",
                add_new_product: "Add new product/service",
                save_and_stay: "Save and continue editing",
                save_and_quit: "Save and go to list",
                Log_in: "Log in",
                Remember_me : "Remember me",
                Forgot_password : "Forgot password?",
                Please_input_your_Username : "Please input your Username (it is email)",
                Please_input_your_Password : "Please input your Password",
                Please_create_your_Password : "Please input your new Password (minimum 8 characters)",
                Please_enter_your_First_Name: "Please enter your First Name",
                Please_enter_your_Last_Name: "Please enter your Last Name",
                Login : 'Login',
                Password : "Password",
                Nov_dokument: "New invoice",
                Vrsta: "Type",
                Račun: "Invoice",
                Predračun: "Bill",
                Številka: "Number",
                Za_naročilo: "",
                Nasprotna_stranka: "Counterpart",
                Datum_dokumenta: "Document date",
                Datum_plačila: "Payment date",
                Shraniti: "Save",
                Tiskanje: "Print",
                Ustvarite_novo_podjetje: "Add new counterpart",
                Naziv_storitva_blaga: "Product or service",
                Količina : "Quantity",
                Davek : "Tax",
                Cena : "Price",
                Dodajte_vrstico: "Add position",
                ID_za_DDV: "VAT ID",
                Hvala_za_zaupanje: "Thank you for your trust",
                Znesek_prosimo_plačajte_na: "Please pay the amount to",
                Ob_plačilu_navedite_referenco: "Please provide a reference when making payment",
                Skupaj : "Total",
                Jezik_dokumenta: "Document language",
                Warehouse: "Warehouse",
                Product_name: "Product name",
                Purchase_price: "Purchase price",
                Movement_date: "Movement date",
                Start_stock: "Start stock",
                Stock_write_off: "Stock write-off",
                Stock_receipt: "Stock receipt",
                Final_stock: "Final stock"
            }
        },
        si: {
            translation: {
                Sart_quantity: "Не забыть указать перевод!!!!",
                On_processing:"V obdelavi",
                May_still_make_changes: "Še vedno lahko opravite prilagoditve",
                Status: "Status",
                Sent_invoice: "Izdani račun",
                Received_invoice: "Prejeti račun",
                Error: "Napaka",
                Osnovni_podatki:'Osnovni podatki',
                Zgodovina_opravljenih_letov : 'Zgodovina naročil',
                Urejanje_podatkov_o_partnerju : 'Urejanje podatkov o partnerju',
                Podatki_dokumenta: "Podatki o dokumentu",
                Invoice_is_booked: "Račun je v poslovnih knjigah",
                No_changes_aalowed: "Ni ga mogoče spremeniti",
                Add_new_bank_account: "Dodajte bančni račun",
                Account_to_which_the_funds_are_to_be_paid: "Račun, na katerega se sredstva knjižijo v dobro",
                bank_account: "Bančni račun",
                before_print: "Pred tiskanjem shranite dokument.",
                IbanError: "Nepravilna oblika IBAN",
                BicError: "Nepravilna oblika BIC",
                Prenesite_dokument: 'Prenesite dokument',
                Prenesite_datoteko_ali_povlecite : 'Prenesite datoteko ali povlecite',
                in_spusti_na_označeno_območje : 'in spusti na označeno območje',
                No_Bank_froinvoice: "Če želite ustvariti izdani račun, dodajte vaši bančne podatke ali kliknite „Ne shranite sprememb“.",
                Postcode: "Poštna številka",
                Search_for_company: "Iskanje podjetja",
                Done: "Zaključeno!",
                January: "Januar",
                February: "Februar",
                March: "Marec",
                April: "April",
                May: "Maj",
                June: "Junij",
                July: "Julij",
                August: "Avgust",
                September: "September",
                October: "Oktober",
                November: "November",
                December: "December",
                No_bank_accs: "Niste dodali še nobenega bančnega računa",
                Bank_accounts: "Bančni računi",
                Edit_bank_account: "Ureditev vašega bančnega računa",
                privacy_confirm_help: "Potrebno je vaše soglasje s obdelavo osebnih podatkov.",
                rules_confirm_help: "Potrebno je vaše soglasje s pogoji uporabe.",
                Select_the_customer: "Določite stranko, ki ji želite izdati račun.",
                Specify_the_date:"Določite datum računa",
                Until_what_date: "Do datuma",
                at_least_one_position: "Dodajte vsaj en artikel",
                Davek_za_DDV: "Davek za DDV",
                isssued_draft:"osnutek",
                issued:"izdan",
                received_confirmed:"prejeto, potrjeno",
                received_rejected:"prejeto, zavrnjeno",
                received_draft:"osnutek",
                paid:"plačan",
                partially_paid_overdue:"delno plačan, zapadli",
                partially_paid:"delno plačan",
                unpaid_overdue:"neplačan, zapadli",
                unpaid:"neplačan",
                draft:"osnutek",
                advance_payment:"predplačilo", 
                Delete_warning: "Ali si to res želite?",
                Delete_text: "Element lahko izbrišete, če zanj ni izstavljenih računov. Izbrisati postavko?",
                Yes: "Da",
                No: "Ne",
                goods: "Blago",
                material: "Material",
                semifinishedProduct: "Polizdelki",
                product: "Izdelek",
                services:  "Storitve",
                advancePayment: "Predplačilo",
                advancePaymentServices: "Predplačilo za storitve",
                Welcomed_by: "Dobrodošli v podjetju ",
                Good_afternoon:"Dober dan.",
                What_do_you_want_to_do:"Kaj želite storiti?",
                Issue_an_invoice_to_a_customer:"Izdati račun stranki",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"Nov račun lahko ustvarite tudi v razdelku \"Dokumenti\".",
                Upload_incoming_invoice:"Prenesite prejeti račun",
                Upload_invoice_scan:"Prenesite skeniran račun v formatu pdf, jpg ali png",
                Upload_a_statement:"Prenesite izpisk",
                Upload_pdf_or_csv:"Prenesite datoteko csv ali skenogram v formatu pdf, jpg ali png",
                Send_us_documents:"Pošljite nam dokumente",
                Contracts_annexes_and_other_documents:"Pogodbe, priloge in drugi dokumenti",
                Tasks:"Naloge",
                No_new_tasks:"Ni novih nalog",
                Interface_language:"Jezik vmesnika",
                Events_in_June:"Dogodki v juniju",
                Salary_payment:"Izplačilo plač",
                Small_checklist:"Majhen kontrolni seznam",
                Has_anyone_had_a_salary_increase:"Ali se je komu povečala plača?",
                Is_the_account_balance_sufficient: "Ali stanje na računu zadošča za izplačila?",
                Statehood_Day:"Dan državnosti",
                Its_a_day_off_in_Slovenia:"V Sloveniji je dela prost dan.",
                Bank_statement:"Bančni izpisek",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Ustvarite bančni izpisek za junij in nam ga naložite",
                Street_and_house_number: "Ulica in hišna številka",
                City: "Mesto",
                ZipCode: "Poštna številka",
                More: "Več",
                Podatki_o_partnerju: "Podatki o partnerju",
                Stranka: "Stranka",
                Izvajalec: "Izvajalec",
                Stranka_ali_izvajalec: "Stranka ali izvajalec",
                Kontaktna_oseba: "Kontaktna oseba",
                Telefon: "Telefon",
                Faks: "Faks",
                Delete: "Brisanje",
                Product: "Izdelek",
                Service: "Storitev",
                Ne_shranite_sprememb: "Ne shranite sprememb",
                Nov_izdani_račun: "Nov izdani račun",
                Nov_prejeti_račun: "Nov prejeti račun",
                from: "od",
                Predogled: "Predogled",
                Vsa: "Vsa",
                Nova: "Nova",
                Zaključena : "Zaključena ",
                Od_datum: "Od datum",
                Do_datum: "Do datum",
                Show_archived: "Prikaži arhivirano",
                Išči: "Išči",
                Sent_invoices: "Izdani računi",
                Received_invoices: "Prejeti računi",
                Vrsta_ddokumenta: "Vrsta ddokumenta",
                Amount: "Znešek",
                Nov_partner: "Nov partner",
                Unit:"Enota",
                CurrentStock:"Trenutna zaloga",
                Cost:"Stroški",
                Price:"Cena",
                Currency:"Valuta",
                Kliknite_za_razvrščanje_padajoče:"Kliknite za razvrščanje padajoče",
                Kliknite_za_razvrščanje_naraščajoče:"Kliknite za razvrščanje naraščajoče",
                Prekliči_razvrščanje:"Prekliči razvrščanje",
                Več: "Več",
                Naziv : 'Naziv',
                Davčna_Številka : 'Davčna Številka',
                Matična_Številka : 'Matična Številka',
                Naslov : 'Naslov',
                Domov : 'Domov',
                Dokumenti : 'Dokumenti',
                Stranke :' Stranke',
                Nastavitve :'Nastavitve',
                Izdelki: "Artikli",
                Odjava : 'Odjava',
                E_pošta : 'E-pošta',
                Staro_geslo : 'Staro geslo',
                Novo_geslo: 'Novo geslo',
                Ime: 'Ime',
                Priimek : 'Priimek',
                Nastavitve_uporabnika : 'Nastavitve uporabnika',
                Nastavitve_podjetja : 'Nastavitve podjetja',
                new_product: "Nov izdelek/storitev",
                add_new_product: "Dodajanje novega izdelka/storitve",
                save_and_stay: "Shranite in nadaljujte z urejanjem",
                save_and_quit: "Shranite in pojdite na seznam",
                Log_in: "Prijava",
                Remember_me : "Zapomni si me",
                Forgot_password : "Pozabljeno geslo?",
                Please_input_your_Username : "Vnesite svoje uporabniško ime (to je email)",
                Please_input_your_Password : "Vnesite geslo",
                Please_create_your_Password: "Vnesite novo geslo (najmanj 8 znakov)",
                Please_enter_your_First_Name: "Prosimo, vnesite svoje ime",
                Please_enter_your_Last_Name: "Prosimo, vnesite svoj priimek",
                Login : 'Login',
                Password : "Geslo",
                Nov_dokument: "Nov račun",
                Vrsta: "Vrsta",
                Račun: "Račun",
                Predračun: "Predračun",
                Številka: "Številka",
                Za_naročilo: "",
                Nasprotna_stranka: "Stranka",
                Datum_dokumenta: "Datum računa",
                Datum_plačila: "Datum plačila",
                Shraniti: "Shraniti",
                Tiskanje: "Tiskanje",
                Ustvarite_novo_podjetje: "Ustvarite novo podjetje",
                Naziv_storitva_blaga: "Naziv storitva blaga",
                Količina : "Količina",
                Davek : "Davek",
                Cena : "Cena",
                Dodajte_vrstico: "Dodajte vrstico",
                ID_za_DDV: "ID za DDV",
                Hvala_za_zaupanje: "Hvala zazaupanje",
                Znesek_prosimo_plačajte_na: "Znesek prosimo plačajte na",
                Ob_plačilu_navedite_referenco: "Ob plačilu navedite referenco",
                Skupaj : "Skupaj",
                Jezik_dokumenta: "Jezik dokumenta",
                Warehouse: "Skladišče",
                Product_name: "Ime izdelka",
                Purchase_price: "Nabavna cena", 
                Movement_date: "Datum",
                Start_stock: "Začetna količina",
                Stock_write_off: "Odpis",
                Stock_receipt: "Sprejem",
                Final_stock: "Končno stanje"
            }
        },
        ru: {
            translation: {
                Sart_quantity: "Начальное количество",
                On_processing:"В обработке",
                May_still_make_changes: "Еще можно внести корректировки",
                Status: "Статус",
                Sent_invoice: "Исходящий счет",
                Received_invoice: "Входящий счет",
                Error: "Ошибка",
                Osnovni_podatki:'Общая информация',
                Zgodovina_opravljenih_letov : 'История заказов',
                Urejanje_podatkov_o_partnerju : 'Редактирование данных о партнере',
                Podatki_dokumenta: "Данные документа",
                Invoice_is_booked: "Счет проведен по бухгалтерии",
                No_changes_aalowed: "Изменять его нельзя",
                Add_new_bank_account: "Добавить рассчетный счет",
                Account_to_which_the_funds_are_to_be_paid: "Счет, на который должны поступить средства",
                bank_account: "Расчетный счет",
                before_print: "Пожалуйста, сохраните документ перед печатью",
                IbanError: "Не корректный формат IBAN",
                BicError: "Не корректный формат BIC",
                Prenesite_dokument: 'Загрузить документ',
                Prenesite_datoteko_ali_povlecite : 'Загрузите файл или перетащите ',
                in_spusti_na_označeno_območje : 'его в эту область',
                No_Bank_froinvoice: "Чтобы создать исходящий счет, добавьте свои банковские реквизиты или нажмите \"Отменить\"",
                Postcode: "Индекс",
                Search_for_company: "Поиск по компаниям",
                Done: "Готово!",
                January: "Январь",
                February: "Февраль",
                March: "Март",
                April: "Апрель",
                May: "Май",
                June: "Июнь",
                July: "Июль",
                August: "Август",
                September: "Сентябрь",
                October: "Октябрь",
                November: "Ноябрь",
                December: "Декабрь",
                No_bank_accs: "Вы еще не добавили ни одного банковского счета",
                Bank_accounts: "Cчета в банках",
                Edit_bank_account: "Ваши банковские реквизиты",
                privacy_confirm_help: "Необходимо Ваше согласие с условиями обработки персональных данных",
                rules_confirm_help: "Необходимо Ваше согласие с условиями использования",
                Select_the_customer: "Укажите клиента, кому хотите выставить счет",
                Specify_the_date:"Укажите дату счета",
                Until_what_date: "До какого числа",
                at_least_one_position: "Добавьте хотя бы одну позицию",
                Davek_za_DDV: "НДС",
                isssued_draft:"черновик",
                issued:"издан",
                received_confirmed:"получен, подтвержден",
                received_rejected:"получен, отклонен",
                received_draft:"черновик",
                paid:"оплачен",
                partially_paid_overdue:"частично оплачен, просрочен",
                partially_paid:"частично оплачен",
                unpaid_overdue:"неоплачен, просрочен",
                unpaid:"неоплаченный",
                draft:"черновик",
                advance_payment:"авансовый платёж",
                Delete_warning: "Это то, что Вы хотите?",
                Delete_text: "Удалить товар можно, если по нему нет счетов. Удалить товар?",
                Yes: "Да",
                No: "Нет",
                goods: "Товар",
                material: "Материал",
                semifinishedProduct: "Полуфабрикат",
                product: "Продукт",
                services:  "Услуга",
                advancePayment: "Авансовый платеж",
                advancePaymentServices: "Авансовый платеж за услуги",
                Welcomed_by: "Вас приветствует компания ",
                Good_afternoon:"Добрый день.",
                What_do_you_want_to_do:"Что хотите сделать?",
                Issue_an_invoice_to_a_customer:"Выписать счет клиенту",
                You_can_also_create_a_new_invoice_from_the_Documents_section:"Создать новый счет можно также из раздела 'Документы'",
                Upload_incoming_invoice:"Загрузить входящий счет ",
                Upload_invoice_scan:"Загрузите скан счета в формате pdf, jpg или png",
                Upload_a_statement:"Загрузить выписку",
                Upload_pdf_or_csv:"Загрузите csv-файл или скан  в формате pdf, jpg или png",
                Send_us_documents:"Отправить нам документы",
                Contracts_annexes_and_other_documents:"Договора, приложения, и другие документы",
                Tasks:"Задачи",
                No_new_tasks:"Новых задач нет",
                Interface_language:"Язык интерфейса",
                Events_in_June:"События в июне",
                Salary_payment:"Выплата зарплаты",
                Small_checklist:"Небольшой чек-лист:",
                Has_anyone_had_a_salary_increase:"A не было ли у кого-то повышения зарлаты?",
                Is_the_account_balance_sufficient: "Достаточен ли баланс на счете для выплат?",
                Statehood_Day:"День государственности",
                Its_a_day_off_in_Slovenia:"В Словении в этот день выходной",
                Bank_statement:"Выписка из банка",
                Generate_a_bank_statement_for_June_and_upload_it_for_us:"Сформируйте банковскую выписку за июнь и загрузите ее для нас",
                Street_and_house_number: "Улица и номер дома",
                City: "Город",
                ZipCode: "Индекс",
                More: "Еще",
                Podatki_o_partnerju: "Даннные о партнере",
                Stranka: "Клиент",
                Izvajalec: "Подрядчик",
                Stranka_ali_izvajalec: "Клиент или подрядчик",
                Kontaktna_oseba: "Контактное лицо",
                Telefon: "Телефон",
                Faks: "Факс",
                Delete: "Удалить",
                Product: "Товар",
                Service: "Услуга",
                Ne_shranite_sprememb: "Отменить",
                Nov_izdani_račun: "Новый исходящий счет",
                Nov_prejeti_račun: "Новый входящий счет",
                from: "от",
                Predogled: "Предпросмотр",
                Vsa: "Все",
                Nova: "Новые",
                Zaključena : "Завершенные",
                Od_datum: "От даты",
                Do_datum: "До даты",
                Show_archived: "Показать архивные",
                Išči: "Найти",
                Sent_invoices: "Исходящие счета",
                Received_invoices: "Входящие счета",
                Vrsta_ddokumenta: "Тип документа",
                Amount: "Сумма",
                Nov_partner: "Новый контрагент",
                Unit:"Ед. изм.",
                CurrentStock:"Текущий остаток",
                Cost:"Себестоимость",
                Price:"Цена",
                Currency:"Валюта",
                Kliknite_za_razvrščanje_padajoče:"Сортировать по возорастанию",
                Kliknite_za_razvrščanje_naraščajoče:"Сортровать по убыванию",
                Prekliči_razvrščanje:"Сбросить сортировку",
                Več: "Детали",
                Naziv : 'Название',
                Davčna_Številka : 'Налоговый номер',
                Matična_Številka : 'Рег. номер',
                Naslov : 'Адрес',
                Domov : 'Главная',
                Dokumenti : 'Документы',
                Stranke :' Партнеры',
                Nastavitve :'Настройки',
                Izdelki: "Товары и услуги",
                Odjava : 'Выход',
                E_pošta : 'Email',
                Staro_geslo : 'Старый пароль',
                Novo_geslo: 'Новый пароль',
                Ime: 'Имя',
                Priimek : 'Фамилия',
                Nastavitve_uporabnika : 'Настройки пользователя',
                Nastavitve_podjetja : 'Настройки компании',
                new_product: "Новый товар/услуга",
                add_new_product: "Добавить новый товар/услугу",
                save_and_stay: "Сохранить и продолжить редактирование",
                save_and_quit: "Сохранить и вернуться к списку",
                Log_in: "Вход",
                Remember_me : "Запомнить меня",
                Forgot_password : "Забыли пароль?",
                Please_input_your_Username : "Пожалуйста, введите имя пользователя (это email)",
                Please_input_your_Password : "Пожалуйста, введите пароль",
                Please_create_your_Password : "Пожалуйста, придумайте пароль (минимум 8 знаков)",
                Please_enter_your_First_Name: "Пожалуйста, укажите Ваше имя",
                Please_enter_your_Last_Name: "Пожалуйста, укажите Вашу фамилию",
                Login : 'Логин',
                Password : "Пароль",
                Nov_dokument: "Новый счет",
                Vrsta: "Тип",
                Račun: "Cчет",
                Predračun: "Смета",
                Številka: "Номер",
                Za_naročilo: "",
                Nasprotna_stranka: "Контрагент",
                Datum_dokumenta: "Дата документа",
                Datum_plačila: "Дата платежа",
                Shraniti: "Сохранить",
                Tiskanje: "Печать",
                Ustvarite_novo_podjetje: "Новый контрагент",
                Naziv_storitva_blaga: "Товар или усулга",
                Količina : "Кол-во",
                Davek : "НДС",
                Cena : "Цена",
                Dodajte_vrstico: "Добавить позицию",
                Hvala_za_zaupanje: "Благодарим вас за доверие",
                Znesek_prosimo_plačajte_na: "Пожалуйста, перечислите сумму на",
                Ob_plačilu_navedite_referenco: "Пожалуйста, укажите ссылку при оплате",
                Skupaj : "Всего",
                Jezik_dokumenta: "Язык документа",
                Warehouse: "Склад",
                Product_name: "Название товара",
                Purchase_price: "Цена закупки",
                Movement_date: "Дата",
                Start_stock: "Остаток на момент движения",
                Stock_write_off: "Списание",
                Stock_receipt: "Поступление",
                Final_stock: "Конечный остаток"
            }       
        }



    },
    lng: 'en',
    debug: true,
})
export default i18n;